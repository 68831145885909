import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "app/store";

interface WalletState {
  walletAddress: string;
  connected: boolean;
}

const initialState: WalletState = {
  walletAddress: "",
  connected: false,
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setWalletAddress: (state: WalletState, action: PayloadAction<string>) => {
      state.walletAddress = action.payload;
    },
    setConnected: (state: WalletState, action: PayloadAction<boolean>) => {
      state.connected = action.payload;
    },
  },
});

export const { setWalletAddress, setConnected } = walletSlice.actions;

export const selectWallet = (state: RootState) => state.wallet;

export default walletSlice.reducer;
