import Layout from "components/Layout/Layout";
import BusinessNav from "./components/BusinessNav";
import AppWrapper from "./routes";


export default function index() {
  return (
    <Layout presentLocation="/business">
      <BusinessNav />
      <section className="m-2">
        <AppWrapper />
      </section>
    </Layout>
  );
}
