import { Contract } from "ethers";
import { Web3Provider } from "@ethersproject/providers";
import isuperTokenabi from "./abi/isuperToken.json";
import NativeCompanyAbi from "./NativeCompany.json";
import CfaAbi from "./CFA.json";
import isuperFluidabi from "./ISuperfluid.json";
import trustMainabi from "./TrustMain.json";
import TrustToken from "utils/TrustToken.json";
import trustPersonalabi from "./TrustPersonal.json";
import trustidabai from "./TrustIDA.json";

export const convertGweiToUsd = (amount: string | undefined) => {
  if (!amount) return 0;
  return amount;
};

export const toWei = (amount: string) => {
  if (!amount) return 0;
  return amount;
};

export const hello = () => {
  return "Hello";
};

export const isuperToken = (address: string, library: Web3Provider | any) => {
  const erc20Contract = new Contract(address, isuperTokenabi.abi, library);
  return erc20Contract;
};

export const nativeCompany = (address: string, library: Web3Provider | any) => {
  const nativeCompany = new Contract(address, NativeCompanyAbi.abi, library);
  return nativeCompany;
};

export const CFA = (address: string, library: Web3Provider | any) => {
  const CFA = new Contract(address, CfaAbi.abi, library);
  return CFA;
};

export const isuperFluid = (address: string, library: Web3Provider | any) => {
  const isuperFluid = new Contract(address, isuperFluidabi.abi, library);
  return isuperFluid;
};

export const trustMain = (address: string, library: Web3Provider | any) => {
  const trustMain = new Contract(address, trustMainabi.abi, library);
  return trustMain;
};

export const trustToken = (address: string, library: any) => {
  const trustToken = new Contract(address, TrustToken.abi, library);
  return trustToken;
};
export const personalContract = (address: string, library: Web3Provider | any) => {
  const personalContract = new Contract(address, trustPersonalabi.abi, library);
  return personalContract;
};

export const trustIDA = (address: string, library: Web3Provider | any) => {
  const trustIDA = new Contract(address, trustidabai.abi, library);
  return trustIDA;
};
