import { Suspense } from "react";
import { Spinner } from "react-bootstrap";

export default function WithSuspense(WrappedComponent: any) {
  function ComponentWithSuspense(props: any) {
    const fallbackComponent = (
      <div className="center_item">
        <Spinner animation="border" />
      </div>
    );
    return (
      <Suspense fallback={fallbackComponent}>
        <WrappedComponent {...props} />
      </Suspense>
    );
  }
  return ComponentWithSuspense;
}
