import { Button, Card, Col, Row } from "react-bootstrap";
import { AiOutlineArrowRight } from "react-icons/ai";
import { GoPrimitiveDot } from "react-icons/go";
import { ImCross } from "react-icons/im";
import { Link } from "react-router-dom";
import AvailablePiechart from "./charts/AvailablePiechart";
import UtilisedPiechart from "./charts/UtilisedPiechart";
/*
import { BUSINESS_PATH } from "../routes/page-paths";

*/

const data = [
  {
    id: 1,
    remark: "Prateek",
    amount: "100",
    coin: "ETH",
    date: "23rd July, 2022",
    status: "Complete",
  },
  {
    id: 2,
    remark: "Ashish",
    amount: "300",
    coin: "BTC",
    date: "22nd July, 2022",
    status: "Rejected",
  },
  {
    id: 3,
    remark: "Tom",
    amount: "150",
    coin: "SOL",
    date: "21st July, 2022",
    status: "Pending",
  },
  {
    id: 4,
    remark: "Celia",
    amount: "500",
    coin: "SOL",
    date: "22/7/2022",
    status: "Complete",
  },
  {
    id: 5,
    remark: "Phidel",
    amount: "50",
    coin: "MATIC",
    date: "15/7/2022",
    status: "Rejected",
  },
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function PaymentPlan({ numberOfEmployees }) {
  return (
    <>
      <Col md={9}>
        <Card className="border-0 card_shadow shadow h-100">
          <Card.Body>
            <Card.Subtitle className="py-3 text-primary mx-2 d-flex justify-content-between">
              <div>Trasactions</div>
              <Link to="/business/transactions" className="text-decoration-none">
                <div>
                  <span className="text-decoration-none">
                    View All <AiOutlineArrowRight />
                  </span>
                </div>
              </Link>
            </Card.Subtitle>
            <b>
              <Row className="border-bottom border-black">
                <Col className="">
                  <div>Transferee</div>
                </Col>
                <Col className="">
                  <div>Amount</div>
                </Col>
                <Col className="">
                  <div>Date</div>
                </Col>
                <Col className="">
                  <div>Coin</div>
                </Col>
                <Col className="">
                  <div>Status</div>
                </Col>
              </Row>
            </b>
            <Col>
              {data.length == 0 ? (
                <div className="h-100">
                  <p className="text-muted items-center text-center justify-content-center mt-5 p-">
                    Time to start doing payments
                  </p>
                </div>
              ) : (
                data.slice(0, 3).map((item) => {
                  if (item.status === "Complete") {
                    return (
                      <Row className="p-2 border-bottom border-black" key={item.id}>
                        <Col className="">
                          <div>{item.remark}</div>
                        </Col>
                        <Col className="">
                          <div>{item.amount}</div>
                        </Col>
                        <Col className="">
                          <div>{item.date}</div>
                        </Col>
                        <Col className="">
                          <div>{item.coin}</div>
                        </Col>
                        <Col className="">
                          <div className="d-flex">
                            <GoPrimitiveDot className="text-success my-auto" />{" "}
                            <p className="text-success my-auto mx-1">{item.status} </p>
                          </div>
                        </Col>
                      </Row>
                    );
                  } else if (item.status === "Rejected") {
                    return (
                      <Row className="p-2 border-bottom border-black" key={item.id}>
                        <Col className="">
                          <div>{item.remark}</div>
                        </Col>
                        <Col className="">
                          <div>{item.amount}</div>
                        </Col>
                        <Col className="">
                          <div>{item.date}</div>
                        </Col>
                        <Col className="">
                          <div>{item.coin}</div>
                        </Col>
                        <Col className="">
                          <div className="d-flex">
                            <ImCross className="text-danger my-auto" />{" "}
                            <p className="text-danger my-auto mx-1">{item.status} </p>
                          </div>
                        </Col>
                      </Row>
                    );
                  } else {
                    return (
                      <Row className="p-2 border-bottom border-black" key={item.id}>
                        <Col className="">
                          <div>{item.remark}</div>
                        </Col>
                        <Col className="">
                          <div>{item.amount}</div>
                        </Col>
                        <Col className="">
                          <div>{item.date}</div>
                        </Col>
                        <Col className="">
                          <div>{item.coin}</div>
                        </Col>
                        <Col className="">
                          <div className="d-flex">
                            <GoPrimitiveDot className="text-warning my-auto" />{" "}
                            <p className="text-warning my-auto mx-1">{item.status} </p>
                          </div>
                        </Col>
                      </Row>
                    );
                  }
                })
              )}
            </Col>
          </Card.Body>
        </Card>
      </Col>
      <Col>
      <Card className="border-0 card_shadow shadow">
              <Card.Body>
                <Card.Subtitle className="py-3 text-primary mx-2 d-flex justify-content-between">
                  <div>Monthly Report</div>
                </Card.Subtitle>
                <Row>
                  <div className="d-flex justify-content-evenly align-items-center">
                    <AvailablePiechart />
                    <UtilisedPiechart />
                  </div>
                </Row>
                <Row>
                  <div className="align-items-center text-center">
                    <a href="" className="text-decoration-none">
                      <Button>
                        View Details <AiOutlineArrowRight />
                      </Button>
                    </a>
                  </div>
                </Row>
              </Card.Body>
            </Card>
            
      </Col>
      {/*
      <div className="col-8">
        <div className="card border-0 card_shadow">
          <div className="card-body">
            <div className="row">
              <p>Manage Payment Plan</p>
              <div className="col-2"></div>
              <div className="col-6">
                <div>
                  <div className="mb-3 row">
                    <input
                      type="text"
                      className="form-control"
                      id="invoiceName"
                      placeholder="Customer wallet address"
                    />
                  </div>
                  <div className="mb-3 row">
                    <input
                      type="text"
                      className="form-control"
                      id="invoiceName"
                      placeholder="Customer wallet address"
                    />
                  </div>
                </div>
                <div className="mx-auto">
                  <div className="d-flex justify-content-between">
                    <button type="button" className="btn btn-primary px-5 text-white ms-auto">
                      Remove
                    </button>
                    <button type="button" className="btn btn-primary px-3 text-white ms-4">
                      Update
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="text-center">
                  <p className="h5">Employees</p>
                  <p className="lead fw-bold">{numberOfEmployees}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-4">
        <div className="card border-0 h-100">
          <div className="card-body card_shadow">
            <p>Help Center</p>
            <div className="d-flex justify-content-center">
              <img src={HelpLogo} width={250} alt="help desk" />
            </div>
          </div>
        </div>
      </div>
      */}
    </>
  );
}
