import { useState, useMemo } from "react";
import { isuperToken } from "utils/helpers";
import { TEST_TOKEN_ADDRESS } from "utils/addresses";
import JSBI from "jsbi";
import { useAccount, useSigner, useNetwork } from "wagmi";

export const useAllowance = (
  contractAddress: string,
  reload: boolean,
  setreload: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  // const { account, library, chainId } = useWeb3React();
  const { address: account } = useAccount();
  const { chain } = useNetwork();
  const signer = useSigner();
  const [allowance, setallowance] = useState<string | undefined>();

  useMemo(async () => {
    if (account) {
      try {
        const token = isuperToken(TEST_TOKEN_ADDRESS[chain?.id as number], signer.data);
        const allowance = await token.allowance(account, contractAddress);

        const decimals = await token.decimals();
        const divideWith = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(decimals));
        const formattedAllowance = JSBI.divide(JSBI.BigInt(allowance.toString()), divideWith);
        setallowance(formattedAllowance.toString());
        setreload(false);
      } catch (err) {
        console.log(err);
      }
    }
  }, [account, signer.data, chain?.id, contractAddress, setreload]);

  return { allowance };
};
