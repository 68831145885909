export const SidebarLinks = [
  {
    title: "Dashboard",
    destination: "dashboard",
    icon: "bi-grid",
  },
  {
    title: "Wallet",
    destination: "wallet",
    icon: "bi-wallet",
  },
  {
    title: "Investment",
    destination: "investment",
    icon: "bi-credit-card",
    exclude: ["/business"],
  },
  {
    title: "Employee",
    destination: "employees",
    icon: "bi-credit-card",
    exclude: ["/personal"],
  },
  {
    title: "Payment",
    destination: "payment",
    icon: "bi-cash-coin",
  },
  {
    title: "Invoice",
    destination: "invoice",
    icon: "bi-receipt",
  },
  {
    title: "Transactions",
    destination: "transactions",
    icon: "bi-credit-card-2-front",
  },
  {
    title: "Announcement",
    destination: "announcement",
    icon: "bi-volume-up",
  },
];