import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import BusinessDashboard from "../../assets/BusinessDashboard.png";
import PersonalDashboard from "../../assets/PersonalDashboard.png";
// import { ConnectorNames } from "utils/connectors";
import useBoolean from "hooks/useBoolean";
import ConnectWalletModal from "components/Modal/ConnectWalletModal";
import { useNavigate } from "react-router-dom";
import { BsFillInfoCircleFill } from "react-icons/bs";

export default function Hero({ isBusinessMode }) {
  const [show, setShow] = useBoolean(false);
  const [mode] = useState(localStorage.getItem("devMode"));

  const navigate = useNavigate();

  const goToWaitlist = () => {
    navigate("/sign-up");
  };

  return (
    <>
      <Row className="text-white">
        <Col md={6} sm={12} className="">
          <Row className=" px-5 mt-5 pt-5">
            {isBusinessMode ? (
              <div>
                <div className="display-5" style={{ fontWeight: "800" }}>
                  Managing Your Crypto Payroll and Benefits Just Easier
                </div>

                <div className="h5 mt-3 text-muted p" style={{ fontWeight: "400" }}>
                  <p style={{ color: "#d0d0d0" }}>
                    Trust Finance provides a secure and cheaper way for businesses to make real-time
                    crypto payments, benefits, and accounting solutions that support a more
                    efficient way of managing your business finances and operations.
                  </p>
                </div>
              </div>
            ) : (
              <div>
                <div className="display-5" style={{ fontWeight: "800" }}>
                  Receive Crypto Payroll and NFT benefits from your Employers Securely
                </div>

                <div className="h5 mt-3 text-muted py-3" style={{ fontWeight: "400" }}>
                  <p style={{ color: "#d0d0d0" }}>
                    We Provide Talents and Freelancers the ability to Earn Crypto In Real-time (Wage
                    on Demand), Invest their Income to Yield Generating Assets, and Track Income for
                    Tax Reporting
                  </p>
                </div>
              </div>
            )}
          </Row>
          <Row>
            <Col className="mx-5">
              {mode === "true" ? (
                <div className="d-flex flex-column">
                  <div>
                    <Button variant="primary" size="lg" className="" onClick={() => setShow.on()}>
                      Get Started
                    </Button>
                  </div>
                  <small className="mt-2 d-flex align-items-center">
                    <BsFillInfoCircleFill />
                    &nbsp; Click on Get Started to connect your wallet
                  </small>
                </div>
              ) : (
                <Button size="lg" variant="primary" className="" onClick={goToWaitlist}>
                  Join Our Waitlist
                </Button>
              )}
              {/* <Button size="lg" variant="light" className="mx-5">
                Learn More
              </Button> */}
            </Col>
          </Row>
        </Col>
        <Col md={6} sm={12} className="mx-auto d-none d-md-block">
          {isBusinessMode ? (
            <img
              src={BusinessDashboard}
              alt="BusinessDashboard"
              className="rounded img-fluid object-contain"
            />
          ) : (
            <img
              src={PersonalDashboard}
              alt="PersonalDashboard"
              className="rounded img-fluid object-contain"
            />
          )}
        </Col>
      </Row>
      <ConnectWalletModal
        isBusinessMode={isBusinessMode}
        show={show}
        handleClose={() => setShow.off()}
      />
    </>
  );
}

{
  /* 
<div className="position-relative">
      <Container fluid className={`hero_bg ${isBusinessMode ? "business_bg" : "personal_bg"}`}>
        <div className="row flex-lg-row-reverse align-items-center py-5">
          <Container className="ps-md-5 px-auto">
            <Row className="ms-md-5 ms-0 mt-1 mt-md-5">
              <Col md={5} sm={12} className="z_10">
                <h1 className="display-6 display-md-3 fw-normal mb-3 ms-0 ms-md-3">
                  Set your business up with real-time payroll payment, benefit, and accounting
                  solution
                </h1>
                <Nav onSelect={() => setOptionSelected.on()} variant="pills" className="d-inline">
                  <Nav.Item>
                    <Nav.Link className="bg-transparent" eventKey="link-1">
                      <div className="text-left hero_button p-2 border border-dark border-1 rounded">
                        <div className="d-flex px-1 px-md-5 align-items-center">
                          {" "}
                          <img
                            className="p-2"
                            src={nft}
                            style={{ width: "2.5rem", height: "2.5rem" }}
                            alt="nft"
                          />
                          <p className="mb-0 ms-2 fs-5">
                            {isBusinessMode ? "I am a NFT Project" : "I am a Web3 Contributor"}
                          </p>
                        </div>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="bg-transparent" eventKey="link-2">
                      <div className="text-left hero_button p-2 border border-dark border-1 rounded">
                        <div className="d-flex px-1 px-md-5 align-items-center">
                          {" "}
                          <img
                            className="p-2"
                            src={DAO}
                            style={{ width: "2.5rem", height: "2.5rem" }}
                            alt="nft"
                          />
                          <p className="mb-0 ms-2 fs-5">
                            {isBusinessMode ? "I am a DAO" : "I am freelancer"}
                          </p>
                        </div>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="bg-transparent" eventKey="link-3">
                      <div className="text-left hero_button p-2 border border-dark border-1 rounded">
                        <div className="d-flex px-1 px-md-5 align-items-center">
                          {" "}
                          <img
                            className="p-2"
                            src={cryptobusiness}
                            style={{ width: "2.5rem", height: "2.5rem" }}
                            alt="nft"
                          />
                          <p className="mb-0 ms-2 fs-5">
                            {isBusinessMode
                              ? "I am a Crypto adopted Business"
                              : "I am a gig worker"}
                          </p>
                        </div>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Row className="mb-5 px-3 py-5">
                  <Col>
                    <Button
                      onClick={handleWalletConnect}
                      disabled={!selectOption}
                      className="w-100 rounded"
                      variant="primary"
                      size="lg"
                    >
                      Get Started
                    </Button>
                  </Col>
                  <Col>
                    <Button className="w-100 rounded" variant="light" size="lg">
                      Learn More
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col></Col>
            </Row>
          </Container>
        </div>
      </Container>
      <Partners />
    </div>
*/
}
