import { ButtonGroup, Dropdown } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { PATHS } from "utils/constants";
import { useAccount, useDisconnect } from "wagmi";

export default function DisconnectButton() {
  const navigate = useNavigate();
  const location = useLocation();
  const { address } = useAccount();
  const { disconnect } = useDisconnect();

  const goToDashboard = () => {
    if (location.pathname !== "/") {
      navigate("/");
      return;
    }
    navigate(PATHS.DASHBOARD);
  };
  return (
    <Dropdown as={ButtonGroup} className="d-flex align-items-center">
      <p className="mb-0 me-2 fw-bold">
        {address?.slice(0, 7)}...{address?.slice(35)}
      </p>

      <Dropdown.Toggle split id="dropdown-split-basic" />

      <Dropdown.Menu>
        <Dropdown.Item onClick={goToDashboard}>
          {location.pathname !== "/" ? "Go Home" : "Go to Dashboard"}
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            disconnect();
            localStorage.clear();
            navigate("/");
          }}
        >
          Log Out
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
