import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import noInvoice from "../../../assets/images/login_bg.png";

const data = [
    {
        id: 1,
        amount: "500",
        coin: "USDC",
        date: "15 Aug 2022",
        type: "Paid",
        wallet: "0x28b000000B0d2aAa7fEc88a78d6109Abb037C9Ee"
    },
    {
        id: 2,
        amount: "500",
        coin: "USDC",
        date: "15 Aug 2022",
        type: "Scheduled",
        wallet: "0x28b000000B0d2aAa7fEc88a78d6109Abb037C9Ee"

    },
    {
        id: 3,
        amount: "500",
        coin: "USDC",
        date: "15 Aug 2022",
        type: "Paid",
        wallet: "0x28b000000B0d2aAa7fEc88a78d6109Abb037C9Ee"
    },
    {
        id: 4,
        amount: "500",
        coin: "USDC",
        date: "15 Aug 2022",
        type: "Scheduled",
        wallet: "0x28b000000B0d2aAa7fEc88a78d6109Abb037C9Ee"
    },
    {
        id: 5,
        amount: "500",
        coin: "USDC",
        date: "15 Aug 2022",
        type: "Paid",
        wallet: "0x28b000000B0d2aAa7fEc88a78d6109Abb037C9Ee"
    },
];

const InvoiceTransaction = () => {
    const [invoiceType, setInvoiceType] = useState("All");
    return (
        <div className="">
            <Row className="p-3 justify-content-evenly">
                <Col>
                    <button onClick={() => setInvoiceType("All")} className="bg-white rounded border w-100 p-1">
                        All
                    </button>
                </Col>
                <Col>
                    <button onClick={() => setInvoiceType("Paid")} className="bg-white rounded border w-100 p-1">
                        Paid
                    </button>
                </Col>
                <Col>
                    <button onClick={() => setInvoiceType("Scheduled")} className="bg-white rounded border w-100 p-1">
                        Scheduled
                    </button>
                </Col>
            </Row>
            <div className="">
                {data.length > 0 ?
                    data.map((item) => {
                        if (invoiceType === "All") {
                            return (
                                <Row className="border-bottom border-black card border-0 card_shadow text-center" key={item.id}>
                                    <Col className="card-body">
                                        <Row>
                                            <p className="" style={{ "fontSize": "13px" }}>
                                                {item.wallet}
                                            </p>
                                        </Row>
                                        <Row className="justify-content-evenly">
                                            <Col>
                                                <p>
                                                    {item.amount} {item.coin}
                                                </p>
                                            </Col>
                                            <Col>
                                                <p>
                                                    {item.date}
                                                </p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            );
                        }
                        else if (invoiceType === "Paid" && item.type === "Paid") {
                            return (
                                <Row className="border-bottom border-black card border-0 card_shadow text-center" key={item.id}>
                                    <Col className="card-body">
                                        <Row>
                                            <p className="" style={{ "fontSize": "13px" }}>
                                                {item.wallet}
                                            </p>
                                        </Row>
                                        <Row className="justify-content-evenly">
                                            <Col>
                                                <p>
                                                    {item.amount} {item.coin}
                                                </p>
                                            </Col>
                                            <Col>
                                                <p>
                                                    {item.date}
                                                </p>
                                            </Col>
                                        </Row>
                                    </Col>

                                </Row>
                            );
                        }
                        else if (invoiceType === "Scheduled" && item.type === "Scheduled") {
                            return (
                                <Row className="border-bottom border-black card border-0 card_shadow text-center" key={item.id}>
                                    <Col className="card-body">
                                        <Row>
                                            <p className="" style={{ "fontSize": "13px" }}>
                                                {item.wallet}
                                            </p>
                                        </Row>
                                        <Row className="justify-content-evenly">
                                            <Col>
                                                <p>
                                                    {item.amount} {item.coin}
                                                </p>
                                            </Col>
                                            <Col>
                                                <p>
                                                    {item.date}
                                                </p>
                                            </Col>
                                        </Row>
                                    </Col>

                                </Row>
                            );
                        }
                    })
                    :
                    <div className="align-items-center justify-content-center text-center bg-white p-3">
                        <div>
                            <img src={noInvoice} className="w-50 mx-auto py-3" alt="" />
                        </div>
                        <div className="text-center">
                            <p className="font-weight-bold h1">No Invoices found?</p>

                        </div>
                        <div className="align-items-center justify-content-center">
                            <p className=" text-center text-muted h5">
                                Try to create more new invoices, send these documents and wait for a payment
                            </p>
                            <Button variant="info" className="w-50">
                                Create Invoices
                            </Button>
                        </div>
                    </div>
                };
            </div>
        </div>
    );
};

export default InvoiceTransaction;