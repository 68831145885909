import { useCallback, useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { useAccount, useNetwork, useSigner } from "wagmi";
import { TEST_TOKEN_ADDRESS } from "utils/addresses";
import { isuperToken, trustToken } from "utils/helpers";
import { ethers } from "ethers";
import { toast } from "react-hot-toast";

type MintModalProps = {
  show: boolean;
  handleClose: () => void;
};

export default function MintModal({ show, handleClose }: MintModalProps) {
  const { address } = useAccount();
  const signer = useSigner();
  const { chain } = useNetwork();

  const [input, setInput] = useState("");

  const mintToken = useCallback(async () => {
    const number = ethers.utils.parseEther(input);
    let superfluidToken: any = null;
    let underlyingToken: any = null;
    if (chain) {
      try {
        superfluidToken = isuperToken(TEST_TOKEN_ADDRESS[chain.id], signer.data);
        const tokenAddress = await superfluidToken.getUnderlyingToken();

        underlyingToken = await trustToken(tokenAddress, signer.data);
        await underlyingToken.mint(address, number);
        await underlyingToken.approve(TEST_TOKEN_ADDRESS[chain.id], number);
        const mintResult = await superfluidToken.upgrade(number);

        if (mintResult) {
          toast.success(`${input} minted successfully`);
          handleClose();
        }
      } catch (e) {
        console.error(e);
        toast.error(
          `Minting failed. Kindly connect with us on ${(
            <a href="https://discord.gg/pqn6sBbnD2">Discord</a>
          )}`,
        );
      }
    }
  }, [address, chain, handleClose, input, signer.data]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="fw-bold">
        Faucet
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column align-items-center">
          <InputGroup>
            <Form.Control
              placeholder="Amount"
              aria-label="Faucet Amount"
              aria-describedby="faucet"
              maxLength={5}
              onChange={(e) => setInput(e.target.value)}
            />
          </InputGroup>
        </div>
        <div className="d-flex justify-content-center py-4">
          <Button disabled={!input.length} onClick={mintToken}>
            Mint Trust Token
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
