//import TransactionTables from "components/Tables/TransactionTables";
import Transaction from "pages/business/components/Transactions/Transaction";

export default function Transactions() {
  return (
    <section>
     {/* <TransactionTables location="transactions" />*/}
      <Transaction />
    </section>
  );
};