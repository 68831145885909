import React from "react";
import ReactApexChart from "react-apexcharts";

class UtilisedPiechart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            series: [34, 66],
            options: {
                colors: ["#0592f0", "#D3D3D3"],
                chart: {
                    width: 380,
                    type: "pie",
                },
                legend: {
                    show: false,
                },
                dataLabels: {
                    enabled: false
                },
                labels: ["Utilised", "Available"],
            },
        };
    }

    render() {
        return (
            <div id="chart" className=''>
                <div className=''>
                    <div>
                        <ReactApexChart options={this.state.options} series={this.state.series} type="pie" width={100} />
                    </div>
                    <div className=''>
                        <p className='p-2'>Utilised <span className=''>$3,400 </span></p>
                    </div>
                </div>
            </div>

        );
    }
}

export default UtilisedPiechart;