import React from "react";
import DashboardCard from "components/Cards/DashboardCard";
import { Col, Row } from "react-bootstrap";
import Graph from "../../../components/charts/Graph";
import Metrics from "../../../components/MetricsCards";


const Dashboard = () => {
  return (
    <section className="px-2">
        <Row>
          <DashboardCard title="Total Income Value" indicatorValue="+3.49%" value="0" mode="dark" />
          <Graph />
          <DashboardCard title="Your Returns" indicatorValue="+2.75%" value="0" mode="dark" />
          
          <Col>
            <Metrics value="0" type="USDC" usage="Investment" image =""/>
          </Col>
          <Col>
            <Metrics value="0" type="USDC" usage="Benefit" image=""/>
          </Col>
          <Col>
            <Metrics value="0" type="USDC" usage="Reward" image=""/>
          </Col>
         
          
        </Row>
      </section>
  );
};

export default Dashboard;