import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import TransactionLabels from "./TransactionLabels";
import { transaction } from "utils/dummyData";


const TransactionDetails = ({showDetails, showSummary}) => {

    const [show, setShow] = useState(false);

    function handleClose(){
        transaction[showSummary].Labels = showDetails.Labels;
        setShow(false);
    }
    const handleShow = () => setShow(true);

  return (
    <div className="mx-auto p-2 border rounded ps-5 mb-4">
    <Row className="text-primary mx-auto" style={{ fontWeight: "800" }}>
        <p className="h5" style={{fontWeight: "800"}}>Details</p>
    </Row>
    <Row>
    <Col className="text-center justify-content-center align-items-center">
        <Row>
            Date: {showDetails.Date}
        </Row>
        <Row>
            Wallet Address: {showDetails.Address}
        </Row>
        <Row>
            Token: {showDetails.Token}
        </Row>
        <Row>
            Send/Recieve: {showDetails.SendReceive}
        </Row>
    </Col>
    <Col className="text-center justify-content-center align-items-center">
        <Row>
            Amount: {showDetails.Amount}
        </Row>
        <Row>
            Gas Fee: 
        </Row>
        <Row>
           Cost Basis: {showDetails.CostBasis}
        </Row>
        <Row>
            Labels:

            {
                showDetails.Labels.map((label, index) => (
                    <Col key={index} md={3}>
                        <div className="border rounded text-center bg-primary text-white" style={{ fontWeight: "800" }}>
                            {label}
                        </div>
                    </Col>
                ))
            }
        </Row>
    </Col>
    </Row>
    <Row>
        <div className="d-flex mt-4 justify-content-center me-5">
        <Button variant="primary" onClick={handleShow}>Add Labels</Button>   
        <TransactionLabels show={show} handleClose={handleClose} showDetails = {showDetails} />
        <Button variant="primary" className="mx-5">Download Transaction Report</Button> 
        </div>
    </Row>
</div>
  );
};

export default TransactionDetails;