import AddEmployeeForm from "./AddEmployeeForm";

export default function EmployeeInput() {
  const convertIntervalToInt = (interval: string) => {
    const day = 60 * 60 * 24;
    if (interval === "Daily") return Number(day);
    if (interval === "Weekly") return Number(day * 7);
    if (interval === "Monthly") return Number(day * 30);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const submitForm = async ({ employeeAddress, interval, salary }) => {
    const amount = Number(salary * 10 ** 18);
    const _interval = convertIntervalToInt(interval);
    console.log(amount);
    console.log(_interval);
  };

  return (
    <>
      <div className="card border-0 card_shadow">
        <div className="card-body">
          <p className="h6 text-primary mb-3">Manage Employees</p>
          <div className="row ms-1">
            <AddEmployeeForm />
          </div>
        </div>
      </div>
    </>
  );
}
