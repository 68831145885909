import { Col } from "react-bootstrap";
import { convertGweiToUsd } from "utils/helpers";

type Props = {
  title: string;
  value: string;
  indicatorValue: string;
  mode: string;
};

export default function DashboardCard({
  title,
  value = "0",
  indicatorValue,
  mode = "dark",
}: Props) {
  return (
    <Col lg={6} md={4} sm={12} className="mb-3 mb-md-0">
      <div className={`card border-0 card_shadow ${mode === "light" ? "" : "bg-primary"}`}>
        <div className={`card-body p-4 ${mode === "light" ? "text-dark" : "text-white"}`}>
          <div className="mb-3">
            {title}
            <span className="ms-3 text-info">
              <i className="bi bi-caret-down-fill"></i>
            </span>
          </div>
          <div>
            <i className="bi bi-arrow-up-right"></i> {indicatorValue}
          </div>
          <p className="h3 fw-normal mt-2 mb-2">
            {/* ${convertGweiToUsd(employeeInfo?.salary?._hex) ?? 0} */}
            {convertGweiToUsd(value ?? "0")}
          </p>
        </div>
      </div>
    </Col>
  );
}
