import React from "react";

const LoanMarket = () => {
    return (
        <div className="mt-3">
            <div className="card border-0 h-100">
                <div className="card-body card_shadow">
                    <p>Loan Market</p>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <p className="mb-0">Open Loan Position</p>
                        <button type="button" className="btn btn-primary px-3 text-white">
                            Buy Stream
                        </button>
                    </div>
                    <p className="text-primary">15</p>
                    <hr />
                    <div className="d-flex justify-content-between">
                        <button type="button" className="btn btn-primary px-3 text-white">
                            Sell Stream
                        </button>
                        <button type="button" className="btn btn-primary px-3 text-white">
                            Stop Stream
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoanMarket;