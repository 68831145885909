import { createContext, useCallback, useState } from "react";
import { USERS } from "utils/constants";

interface IHomePageContext {
  theme: string;
  isBusinessMode: boolean;
  changeTheme?: () => void;
}

const HomepageContext = createContext<IHomePageContext>({
  theme: USERS.BUSINESS,
  isBusinessMode: false,
});

export function HomePageWrapper({ children }) {
  const { BUSINESS, PERSONAL } = USERS;

  const [theme, setTheme] = useState(BUSINESS);

  const isBusinessMode = theme === BUSINESS;

  const changeTheme = useCallback(() => {
    if (isBusinessMode) {
      setTheme(PERSONAL);
    } else {
      setTheme(BUSINESS);
    }
  }, [isBusinessMode, PERSONAL, BUSINESS]);

  return (
    <HomepageContext.Provider value={{ theme, isBusinessMode, changeTheme }}>
      {children}
    </HomepageContext.Provider>
  );
}

export default HomepageContext;
