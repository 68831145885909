import { useCallback, useEffect, useState } from "react";
import { useAccount, useSigner, useNetwork } from "wagmi";
import { CFA } from "utils/helpers";
import { CFA_ADDRESS, NATIVE_COMPANY_ADDRESSES, TEST_TOKEN_ADDRESS } from "utils/addresses";

export const useStreamActive = () => {
  const { address: account } = useAccount();
  const { chain } = useNetwork();
  const signer = useSigner();
  const [activeFlow, setactiveFlow] = useState<boolean>();

  const checkActiveStream = useCallback(async () => {
    try {
      const cfaContract = CFA(CFA_ADDRESS[chain?.id as number], signer.data);
      const flow = await cfaContract.getFlow(
        TEST_TOKEN_ADDRESS[chain?.id as number],
        account,
        NATIVE_COMPANY_ADDRESSES[chain?.id as number],
      );

      const active = parseFloat(flow.flowRate.toString()) === 0 ? false : true;

      setactiveFlow(active);

      console.log("flow", active);
    } catch (err) {
      console.log(err);
    }
  }, [chain?.id, signer.data, account]);

  useEffect(() => {
    if (account) {
      checkActiveStream();
    }
  }, [chain, account, signer.data, checkActiveStream]);

  return { activeFlow };
};
