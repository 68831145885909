import React from "react";
import { Card } from "react-bootstrap";

const CreateIncomeYield = () => {
  return (
    <div className="mx-auto my-auto mt-3">
      <Card className="border-0 card_shadow shadow">
        <Card.Body>
          <Card.Subtitle className="py-3 text-primary mx-2 d-flex justify-content-between">
            <div>Create or Edit Income Yield</div>
          </Card.Subtitle>
          <div className="d-flex flex-column gap-3">
            <input
              type="text"
              className="form-control border border-2 border-primary"
              id="selectWallet"
              placeholder="Select wallet or income stream source"
            />
            <input
              type="text"
              className="form-control border border-2 border-primary"
              id="selectProtocol"
              placeholder="Select yield protocol: Aave, APWINE"
            />
            <div className="d-flex gap-4 justify-content-between">
              <input
                type="text"
                className="form-control border border-2 border-primary"
                id="selectProtocol"
                placeholder="%Allocation"
              />
              <select
                className="form-select border border-2 border-primary"
                aria-label="Default select example"
                defaultValue="daily"
              >
                <option value="daily">Frequency Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
                <option value="annually">Annually</option>
              </select>
            </div>
            <input
              type="text"
              className="form-control border border-2 border-primary"
              id="selectProtocol"
              placeholder="Currency: USDC, DAI, USDT"
            />
          </div>
          <div className="d-flex flex-column mx-auto w-75 gap-2 mt-4">
            <button type="button" className="btn btn-primary px-3 text-white">
              Start
            </button>
            <button type="button" className="btn btn-primary px-3 text-white">
              Update
            </button>
            <button type="button" className="btn btn-primary px-3 text-white">
              Pause
            </button>
          </div>
      </Card.Body>
    </Card>
  </div >
  );
};

export default CreateIncomeYield;