import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { transaction } from "utils/dummyData";
import TransactionDetails from "./TransactionDetails";

const TransactionHistory = () => {
    const [showSummary, setShowSummary] = useState(-1);
    const [showDetails, setShowDetails] = useState(
        {
            Date: "",
            Address: "",
            Token: "",
            SendReceive: "",
            Amount: "",
            CostBasis: "",
            Labels: []
        }
    );

    function displayDetails(item, index) {
        setShowSummary(index);
        showDetails.Date = item.Date;
        showDetails.Address = item.Address;
        showDetails.Token = item.Token;
        showDetails.SendReceive = item.SendReceive;
        showDetails.Amount = item.Amount;
        showDetails.CostBasis = item.CostBasis;
        showDetails.Labels = item.Labels;
        setShowDetails(showDetails);
        item.Labels = showDetails.Labels;
    };

    return (
        <div>
             <Col>
            {
                showSummary > -1 ? (<TransactionDetails showDetails={showDetails} showSummary = {showSummary}/>)
                    : null
            }
            </Col>
            <Col>
                <Container >

                    <Row>
                    </Row>
                    <Row className="text-primary mx-auto" style={{ fontWeight: "800" }}>
                        <Col>
                            Date
                        </Col>
                        <Col>
                            Address
                        </Col>
                        <Col>
                            Token
                        </Col>
                        <Col>
                            Send/Receive
                        </Col>
                        <Col>
                            Labels
                        </Col>

                        <Col>
                            Amount
                        </Col>
                        <Col>
                            Cost Basis
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                    <Row>
                        {
                            transaction.map((item, index) => (
                                <Row key={index} className="p-2 border rounded mx-2 my-2">
                                    <Col>
                                        {item.Date}
                                    </Col>
                                    <Col>
                                        {item.Address}
                                    </Col>
                                    <Col>
                                        {item.Token}
                                    </Col>
                                    <Col>
                                        {item.SendReceive}
                                    </Col>
                                    <Col>
                                    <Row>
                                        {
                                            item.Labels.map((label, index) => (
                                                <Col key={index} sm = {4} md = {10}>
                                                    <div  className="border rounded text-center bg-primary text-white mx-0 m-1" style={{ fontWeight: "800" }}>
                                                        {label}
                                                    </div>
                                                </Col>
                                            ))
                                        }
                                        </Row>
                                    </Col>

                                    <Col>
                                        {item.Amount}
                                    </Col>
                                    <Col>
                                        {item.CostBasis}
                                    </Col>
                                    <Col>
                                        <button className="text-primary border-0 bg-white" onClick={() => displayDetails(item, index)}>Details</button>
                                    </Col>
                                </Row>
                            ))
                        }
                    </Row>
                </Container>
            </Col>
           
        </div>
    );
};

export default TransactionHistory;