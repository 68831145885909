import { useRoutes } from "react-router-dom";
import PersonalDashboard from "../Home";
import PersonalWallet from "../Wallet";
import PersonalInvestment from "../components/Investment";
import Home from "pages/Home";
import Invoice from "../invoice";

export default function PersonalAppWrapper() {
  return useRoutes([
    { path: "/dashboard", element: <PersonalDashboard /> },
    { path: "/wallet", element: <PersonalWallet /> },
    { path: "/invoice", element: <Invoice /> },
    { path: "/investment", element: <PersonalInvestment /> },
    { path: "*", element: <Home /> },
  ]);
}
