import { configureStore } from "@reduxjs/toolkit";
import WalletReducer from "reducers/wallet/walletSlice";
import LayoutReducer from "reducers/layout/layoutSlice";
import AuthReducer from "reducers/auth/authSlice";
import TransactionReducer from "reducers/transactions/transactionsSlice";
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import { encryptTransform } from "redux-persist-transform-encrypt";
// import { REDUX_SECRET_KEY } from "utils/constants";
import { authApi } from "./services/authService";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  // transforms: [
  //   encryptTransform({
  //     secretKey: REDUX_SECRET_KEY,
  //   }),
  // ],
};

const persistedWallet = persistReducer(persistConfig, WalletReducer);
const persistedAuth = persistReducer(persistConfig, AuthReducer);
const persistedTrx = persistReducer(persistConfig, TransactionReducer);

export const store = configureStore({
  reducer: {
    wallet: persistedWallet,
    layout: LayoutReducer,
    [authApi.reducerPath]: authApi.reducer,
    auth: persistedAuth,
    transactions: persistedTrx,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(authApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
