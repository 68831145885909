import EmployeeInput from "../components/EmployeeInput";

export default function ManageEmployees() {
  return (
    <section className="m-4 px-3">
      <div className="mb-3 d-flex justify-content-between">
        <p className="h4">Employees</p>
      </div>
      <EmployeeInput />
    </section>
  );
}