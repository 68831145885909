import {Col, Container, Row } from "react-bootstrap";
import PolygonLogo from "../../assets/images/polygon.png";
import EthGlobalLogo from "../../assets/images/ethglobal.png";
import ChainlinkLogo from "../../assets/images/chainlink.png";
import SuperfluidLogo from "../../assets/images/superfluid.png";
import GitcoinLogo from "../../assets/images/gitcoin_logo.png";
import EncodeLogo from "../../assets/images/encode_logo.png";

const PartnerImages = [
  {
    id: 1,
    image: EthGlobalLogo,
  },
  {
    id: 2,
    image: PolygonLogo,
  },
  {
    id: 3,
    image: ChainlinkLogo,
  },
  {
    id: 4,
    image: SuperfluidLogo,
  },
  {
    id: 5,
    image: GitcoinLogo,
  },
  {
    id: 6,
    image: EncodeLogo,
  },
];

export default function Partners() {
  return (
    <Container className=" pt-md-0 mt-5 ps-5">
        <Row className="d-flex mb-0 align-items-center mx-auto">
          {
            PartnerImages.map((partner) => (
              <Col md={4} key={partner.id} className = "">
                <img className="img-fluid"src={partner.image} alt=""/>
              </Col>
            ))
          }
        </Row>
    </Container>
  );
}
