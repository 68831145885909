import { useState, useMemo } from "react";
import { isuperToken } from "utils/helpers";
import { TEST_TOKEN_ADDRESS } from "utils/addresses";
import JSBI from "jsbi";
import { useAccount, useSigner, useNetwork } from "wagmi";

export const useBalance = () => {
  // const { account, library, chainId } = useWeb3React();
  const { address: account } = useAccount();
  const { chain } = useNetwork();
  const signer = useSigner();
  const [balance, setBalance] = useState<string | undefined>();
  const [symbol, setSymbol] = useState<string | undefined>();

  useMemo(async () => {
    if (account) {
      try {
        const token = isuperToken(TEST_TOKEN_ADDRESS[chain?.id as number], signer.data);
        const balance = await token.balanceOf(account);

        const decimals = await token.decimals();
        const symbol = await token.symbol();
        const divideWith = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(decimals));
        const formattedBalance = JSBI.divide(JSBI.BigInt(balance.toString()), divideWith);
        setBalance(formattedBalance.toString());
        setSymbol(symbol);
      } catch (err) {
        console.log(err);
      }
    }
  }, [account, signer.data, chain?.id]);

  return { balance, symbol };
};
