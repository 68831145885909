import { useContext } from "react";
import { Button, ButtonGroup, Dropdown, Form } from "react-bootstrap";
import Logo from "assets/newlogo.png";
import HomepageContext from "Contexts/HomepageContext";
import { useNavigate } from "react-router-dom";
import { PATHS } from "utils/constants";
import { useAccount, useDisconnect } from "wagmi";

export default function HomeNavbar() {
  const { address } = useAccount();
  const navigate = useNavigate();
  const { disconnect } = useDisconnect();

  const { isBusinessMode, changeTheme } = useContext(HomepageContext);

  const goToDashboard = () => {
    navigate(PATHS.DASHBOARD);
  };

  return (
    <div>
      <nav className="navbar navbar-expand-lg justify-content-evenly text-white font-weight-bold mb-3">
        <div className="d-flex align-items-center">
          <img
            src={Logo}
            width={40}
            height={50}
            alt="trust fe logo"
            className="mr-0 align-items-center justify-content-center mt-2"
          />{" "}
          <p className="mt-4 h3" style={{ fontWeight: "900" }}>
            TRUST
          </p>
        </div>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="d-flex align-items-center mt-2">
          <p
            className={`mb-0 me-2 h5 ${isBusinessMode ? "text-white" : "text-secondary"}`}
            style={{ fontWeight: "900" }}
          >
            Business
          </p>
          <Form.Check type="switch" className="fs-4" id="toggleView" onChange={changeTheme} />
          <p
            className={`mb-0 h5 ${!isBusinessMode ? "text-white" : "text-secondary"}`}
            style={{ fontWeight: "900" }}
          >
            Personal
          </p>
        </div>

        <div className="d-flex">
          <div className="d-flex align-items-center">
            {address ? (
              <>
                <Dropdown as={ButtonGroup}>
                  <Button variant="success">Connected</Button>

                  <Dropdown.Toggle split variant="success" id="dropdown-split-basic" />

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={goToDashboard}>Go to Dashboard</Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        disconnect();
                        localStorage.clear();
                        navigate("/");
                      }}
                    >
                      Log Out
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            ) : (
              <button className="btn btn-secondary connectButton">Not Connected</button>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
}
