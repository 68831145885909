import useBoolean from "hooks/useBoolean";
import { Col, Nav, Row } from "react-bootstrap";
import InvestmentInput from "./InvestmentInput";
import InvoiceTransaction from "./InvoiceTransaction";
import MetricsCards from "./MetricsCards";

const data = [
    {
        id: 1,
        name: "Aave",
        Symbol: "APY",
        Percentage: "8%",
        image: ""
    },
    {
        id: 2,
        name: "Compund",
        Symbol: "APY",
        Percentage: "12%",
        image: ""
    },
    {

        id: 3,
        name: "MakerDAO",
        Symbol: "APY",
        Percentage: "10%",
        image: ""
    }, 
    {
        id: 1,
        name: "PhoenixDAO",
        Symbol: "APY",
        Percentage: "5%",
        image: ""
    }
  ];


const PersonalInvestment = () => {
    const [selectOption, setOptionSelected] = useBoolean(false);

  return (
    <div>
        <div>
            <input
                type="text"
                className="form-control border-bottom" 
                placeholder="Search"
                autoFocus 
            />
            </div>
           
        
       
        <Nav onSelect={() => setOptionSelected.on()} variant="pills" className="d-inline">
        <Row className = "p-3">
        {
                data.map((item, index) => (
                    <Col key={item.id}>
                    <Nav.Item>
                    <Nav.Link className="bg-transparent" eventKey= {`link-${index}`}>
                    
                    <MetricsCards value = {item.name} type = {item.Symbol} usage = {item.Percentage} image = {item.image} />
                   
                    </Nav.Link>
                    </Nav.Item>
                     </Col>
                ))
            }
         </Row>
        </Nav>
       
        <Row selectOption = {selectOption}>
            <InvestmentInput />
        </Row>
        <Row>
            <InvoiceTransaction />
        </Row>
    </div>
  );
};

export default PersonalInvestment;