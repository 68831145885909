import { useState } from "react";
import { Button, Dropdown, DropdownButton, Form, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import AddMilestone from "./AddMilestone";

type CreateInvoiceProps = {
  onHide: () => void;
  show: boolean;
};

const CreateInvoice = ({ onHide, show }: CreateInvoiceProps) => {
  const [invoiceType, setInvoiceType] = useState("Simple");
  const { register, control, handleSubmit } = useForm();

  const submitForm = (data: any) => {
    console.log("adasf");
    console.log(data);
  };

  return (
    <Modal size="lg" show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Create Invoice</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex mb-3">
          <DropdownButton
            id="dropdown-basic-button"
            className="text-capitalize"
            title={invoiceType}
          >
            <Dropdown.Item onClick={() => setInvoiceType("Simple")}>Simple Invoice</Dropdown.Item>
            <Dropdown.Item onClick={() => setInvoiceType("Milestone")}>
              Milestone Invoice
            </Dropdown.Item>
          </DropdownButton>
        </div>
        <Row>
          <Form onSubmit={handleSubmit(submitForm)}>
            <Form.Group className="mb-2" controlId="formReceiverAddress">
              <Form.Label>Clients&apos; wallet Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Client's Wallet Address"
                {...register("_employerAddress")}
              />
            </Form.Group>

            <div className="d-flex gap-4">
              {invoiceType === "Milestone" ? (
                <></>
              ) : (
                <Form.Group className="mb-2 w-50" controlId="formAmount">
                  <Form.Label>Amount</Form.Label>
                  <Form.Control type="number" placeholder="Amount" {...register("amount")} />
                </Form.Group>
              )}
              <Form.Group className="mb-2 w-50" controlId="formToken">
                <Form.Label>Token</Form.Label>
                <Form.Select aria-label="Default select example" {...register("token")}>
                  <option>Select Token</option>
                  <option value="1">USDC</option>
                  <option value="2">BTC</option>
                  <option value="3">ETH</option>
                </Form.Select>
              </Form.Group>
            </div>
            {invoiceType === "Milestone" ? (
              <div className="mt-3">
                <AddMilestone register={register} control={control} />
                <small className="text-secondary mt-3">
                  <i className="bi bi-info-circle" /> Click on the Add and Remove button to add or
                  remove milestones
                </small>
              </div>
            ) : (
              <div className="d-flex">
                <Form.Group className="mb-2 w-50" controlId="formDeadline">
                  <Form.Label>Due Date</Form.Label>
                  <Form.Control type="date" placeholder="Enter Date" {...register("deadline")} />
                </Form.Group>
              </div>
            )}
          </Form>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
        <Button variant="primary" type="submit" onClick={handleSubmit(submitForm)}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateInvoice;
