import CreateIncomeYield from "components/CreateIncomeYield";
import LoanMarket from "components/LoanMarket";
import React from "react";
import { Row } from "react-bootstrap";

const IncomeYieldandLoan = () => {
  return (
    <Row>
        <CreateIncomeYield />
        <LoanMarket />
    </Row>
  );
};

export default IncomeYieldandLoan;