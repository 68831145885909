import BusinessDashboardWrapper from "pages/business";
import PersonalDashboardWrapper from "pages/personal";
import { Unauthenticated } from "routes";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { setLoggedIn } from "reducers/auth/authSlice";
import { USERS } from "utils/constants";

export default function AppHome() {
  const dispatch = useAppDispatch();
  const { isLoggedIn, authenticatedUserType } = useAppSelector((state) => state.auth);

  const dashboardRoutes = [
    "/dashboard",
    "/wallet",
    "/investment",
    "/transactions",
    "/employees",
    "/payment",
    "/wallet",
    "/employees/manage",
  ];

  const { pathname } = useLocation();

  if (location.pathname === "/") {
    dispatch(setLoggedIn(false));
  }
  if (dashboardRoutes.includes(pathname)) {
    dispatch(setLoggedIn(true));
  }

  if (isLoggedIn) {
    if (authenticatedUserType === USERS.PERSONAL) return <PersonalDashboardWrapper />;
    if (authenticatedUserType === USERS.BUSINESS) return <BusinessDashboardWrapper />;
  }

  return <Unauthenticated />;
}