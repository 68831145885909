export const TEST_TOKEN_ADDRESS = {
  "80001": "0xcA5Bf47458b31Cd57BF6503B9785303b8CD529D2",
  "137": "0x3df436ec60EE6bD0Ef8df65b9d8e65669D0F063B",
};

// Should not be changed
export const HOST_ADDRESS: { [key: string]: string } = {
  "80001": "0xEB796bdb90fFA0f28255275e16936D25d3418603",
};

//superfluid cfa - should not change anymore 11/14/2022
export const CFA_ADDRESS: { [key: string]: string } = {
  "80001": "0x49e565Ed1bdc17F3d220f72DF0857C26FA83F873",
};

//trust cfa
export const NATIVE_COMPANY_ADDRESSES: { [key: string]: string } = {
  "80001": "0x0baEFc5B95D0a7770319BfD4148238A0783544CB",
};

export const TRUST_MAIN_ADDRESSES: { [key: string]: string } = {
  "80001": "0x145162f9648A8BC4093D6D4D8300B9f40b87fE68",
};

export const TRUST_IDA_ADDRESSES: { [key: string]: string } = {
  "80001": "0x0366092827f2eB532E1B1088f3F73D4eF1C212b2",
};

export const PERSONAL_CONTRACT_ADDRESSES: { [key: string]: string } = {
  "80001": "0xC9b037fF12Af4cd451D8c769672590e4818A9958",
};
