import { Link } from "react-router-dom";
import { convertGweiToUsd } from "utils/helpers";
import { BsFillPlusCircleFill, BsPersonCircle } from "react-icons/bs";
import { Button } from "react-bootstrap";
import { BUSINESS_PATH } from "../routes/page-paths";

type Props = {
  moneyIn: string | undefined;
  symbol: string | undefined;
};

export default function MetricsCards({ moneyIn, symbol }: Props) {
  return (
    <>
      <div className="row">
        <div className="col-9">
          <div className="row">
            <div className="col">
              <div className="card border-0 card_shadow bg-primary">
                <div className="card-body p-4 text-white">
                  <p>Account Balance</p>
                  <p className="h1 mt-4 mb-2">
                    {convertGweiToUsd(moneyIn)} {symbol}
                  </p>
                  <div className="d-flex justify-content-end">
                    <Link to={BUSINESS_PATH.PAYMENT}>
                      <Button variant="light">Make Payment</Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card border-0 card_shadow h-100">
                <div className="card-body p-4 text-dark">
                  <p style={{ fontSize: "large" }}>Statistics</p>
                  <div className="row">
                    <div className="col justify-content-between">
                      <p>Income</p>
                      <strong className="h1 mb-2">{0}</strong>
                    </div>
                    <div className="col">
                      <p>Expenses</p>
                      <strong className="h1 mb-2">{0}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card border-0 card_shadow shadow h-100 direct_payment">
            <div className="card-body p-4 text-dark">
              <p className="text-primary">Make Payment</p>

              <div className="justify-content-evenly">
                <p>Complete your payment in two clicks</p>
                <div className="d-flex justify-content-evenly">
                  <Link to="/business/payment" className="text-decoration-none">
                    <BsFillPlusCircleFill size={30} className="text-primary payment_button" />
                  </Link>
                  <BsPersonCircle size={30} className="text-primary" />
                  <BsPersonCircle size={30} className="text-primary" />
                  <BsPersonCircle size={30} className="text-primary" />
                  <BsPersonCircle size={30} className="text-primary d-none d-xl-block" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
