import { Card, Col, Container, Row } from "react-bootstrap";
import Pricing from "../../assets/icons/growth.png";
import Reporting from "../../assets/icons/reporting.png";
import Documentation from "../../assets/icons/documentation.png";
import HumanSupport from "../../assets/icons/human_support.png";
import { motion } from "framer-motion";


const businessAdvantages = [
  {
    id: 1,
    title: "Automated Batch Payment",
    description:
      "The best part about having your business in Trust Finance is that we don't just handle payroll—we make it easy. We provide easy payroll payments and management for your businesses by reducing gas fee and time.",
    img: Pricing,
  },
  {
    id: 2,
    title: "Simple Transaction Tracking",
    description:
      "Simplify your accounting process with this simple, yet powerful tool. You can track transactions by business, department or asset account and maintain all of your books in one place.",
    img: Reporting,
  },
  {
    id: 3,
    title: "EASY API DOCUMENTATION",
    description:
      "We support an easy to use API with documentation, allowing you to integrate the platform into your existing tools.",
    img: Documentation,
  },
  {
    id: 4,
    title: "Tax and Compliance Report",
    description:
      "You get oversight of all your accounts and the tools to meet your tax obligations. In addition, we help you manage growth through asset management, risk management and access to lending solutions.",
    img: HumanSupport,
  },
];

const personalAdvantages = [
  {
    id: 1,
    title: "Earn Income in Real Time",
    description:
      "Get access to real-time wages from employers by getting paid per second. Receive your payroll payments without having to wait for weeks and months.",
    img: Pricing,
  },
  {
    id: 2,
    title: "Invest and Generate Yield in Real Time",
    description:
      "Allocate some of your income into a lending or staking protocol to generate yield without having to wait for days or pay high gas fees.",
    img: Reporting,
  },
  {
    id: 3,
    title: "Milestone-Based Invoicing",
    description:
      "Send invoices to DAO, and employers to get paid quicker. Customize invoices using milestone-based invoicing to get paid based on achieving project milestones.",
    img: Documentation,
  },
  {
    id: 4,
    title: "Tax Report",
    description:
      "Get access to transaction reports that you can use to file taxes without having to manually track all transaction records, gas fees, and crypto gains/losses.",
    img: HumanSupport,
  },
];

export default function Advantages({ isBusinessMode }) {
  return (
    <Container className="p-4 align-content-center items-center text-center py-5">
      <div className="top-50 end-50 text-center p-4 text-white mt-5 py-auto">
        <h1 className="display-3 mt-5 mt-lg-0 pt-5 pt-lg-0" style={{fontWeight:"800"}}>Core Advantages</h1>
       {isBusinessMode ? 
       ( <p> Trust Finance Provides Scheduled Batch Payroll Payments, Simple Transaction Tracking, Tax and Compliance Reporting, Easy API Integration</p>) : ( <p> Secure Payments for Freelancers, DAO members, and Gig workers</p>)
        }
      </div>
      <Row className="d-flex p-5">
        {isBusinessMode ? (businessAdvantages.map((advantage) => (
          <Col md={3} sm={12} key={advantage.id}>
            <motion.div className ="w-100 h-100 rounded p-2" whileHover={{scale: 1.1, textShadow: "0px 0px 8px rgb(255, 255, 255)", boxShadow: "3px 3px 8px" }}>
            <Card className="border-0 justify-content-center align-items-center h-100 text-center">
              <Card.Img variant="top" src={advantage.img} className=" rounded core_adv" />
              <Card.Body className="p-0">
                <Card.Title className="my-4 fs-6 h1">{advantage.title.toUpperCase()}</Card.Title>
                <Card.Text className="pe-4 p-2 m-auto">{advantage.description}</Card.Text>
              </Card.Body>
            </Card>
            </motion.div>
          </Col>
        )))
      :
      (personalAdvantages.map((advantage) => (
        <Col md={3} sm={12} key={advantage.id}>
            <motion.div className ="w-100 h-100 rounded p-2" whileHover={{scale: 1.1, textShadow: "0px 0px 8px rgb(255, 255, 255)", boxShadow: "3px 3px 8px" }}>
            <Card className="border-0 justify-content-center align-items-center h-100 text-center">
              <Card.Img variant="top" src={advantage.img} className=" rounded core_adv" />
              <Card.Body className="p-0">
                <Card.Title className="my-4 fs-6 h1">{advantage.title.toUpperCase()}</Card.Title>
                <Card.Text className="pe-4 p-2 m-auto">{advantage.description}</Card.Text>
              </Card.Body>
            </Card>
            </motion.div>
          </Col>
      )))
      }
      </Row>
    </Container>
  );
}
