import { lazy } from "react";
import { useRoutes } from "react-router-dom";
import WithSuspense from "hoc/WithSuspense";
import { PATHS } from "utils/constants";

const Home = WithSuspense(lazy(() => import("pages/Home")));
const Registration = WithSuspense(lazy(() => import("pages/Registration/Registration")));
const BusinessRegistration = WithSuspense(
  lazy(() => import("pages/Registration/BusinessRegistration")),
);
const PersonalRegistration = WithSuspense(
  lazy(() => import("pages/Registration/PersonalRegistration")),
);
const BetaRegistration = WithSuspense(lazy(() => import("pages/Registration/SignUpBeta")));
const Login = WithSuspense(lazy(() => import("pages/Login")));
const DevModePage = WithSuspense(lazy(() => import("pages/DevMode")));
const AdminDashboard = WithSuspense(lazy(() => import("pages/admin")));
const BusinessDashboard = WithSuspense(lazy(() => import("pages/business")));
const PersonalDashboard = WithSuspense(lazy(() => import("pages/personal")));

export function Unauthenticated() {
  const routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: PATHS.REGISTRATION, element: <Registration /> },
    { path: PATHS.PERSONAL_REGISTRATION, element: <PersonalRegistration /> },
    { path: PATHS.BUSINESS_REGISTRATION, element: <BusinessRegistration /> },
    { path: PATHS.LOGIN, element: <Login /> },
    { path: PATHS.BETA_SIGNUP, element: <BetaRegistration /> },
    { path: PATHS.SECRET_PAGE, element: <DevModePage /> },
    { path: "*", element: <Home /> },
  ]);
  return routes;
}

export function Authenticated() {
  const routes = useRoutes([
    //TODO remove this path
    { path: PATHS.ADMIN_DASHBOARD, element: <AdminDashboard /> },
    { path: PATHS.BUSINESS_DASHBOARD, element: <BusinessDashboard /> },
    { path: PATHS.PERSONAL_DASHBOARD, element: <PersonalDashboard /> },
  ]);
  return routes;
}

export function Admin() {
  const routes = useRoutes([{ path: PATHS.ADMIN_DASHBOARD, element: <AdminDashboard /> }]);
  return routes;
}

export function AllRoutes() {
  const routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: PATHS.REGISTRATION, element: <Registration /> },
    { path: PATHS.BUSINESS_REGISTRATION, element: <BusinessRegistration /> },
    { path: PATHS.LOGIN, element: <Login /> },
    { path: PATHS.ADMIN_DASHBOARD, element: <AdminDashboard /> },
    { path: PATHS.BUSINESS_DASHBOARD, element: <BusinessDashboard /> },
    { path: PATHS.PERSONAL_DASHBOARD, element: <PersonalDashboard /> },
    { path: "*", element: <Home /> },
  ]);
  return routes;
}
