import { Row, Button } from "react-bootstrap";
import { Component, useState } from "react";
import TokenTransactions from "./TokenTransactions";
import TransactionHistory from "./TransactionHistory";



const Transaction = () => {

    const [transactionType, setTransactionType] = useState("Transaction");

    return (
        <div className="">
            <Row className="mx-5 py-4 text-primary"><h1 style={{ fontWeight: "900" }}>Transaction History</h1></Row>
            <Row className="text-white border-bottom mb-5">
                <div className="justify-content-evenly d-flex">
                    <Button className={transactionType === "Transaction" ? "bg-primary text-white" : "bg-white border-bottom border-0 border-primary text-primary border-5 "} onClick={() => setTransactionType("Transaction")}>Transactions</Button>
                    <Button className={transactionType === "Token" ? "bg-primary text-white" : " bg-white border-bottom border-0 border-primary text-primary border-5"} onClick={() => setTransactionType("Token")}>Token Transactions</Button>
                </div>
            </Row>
            {transactionType === "Token" && <Component />? (
                <div>
                   <TokenTransactions />
                </div>) : 
                <TransactionHistory /> 
        }
        </div>
    );
};

export default Transaction;