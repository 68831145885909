import { useContext } from "react";
import Hero from "components/Home/Hero";
import Advantages from "components/Home/Advantages";
import Features from "components/Home/Features";
// import Pricing from "components/Home/Pricing";
import HomepageContext from "Contexts/HomepageContext";
import Partners from "components/Home/Partners";
import Footer from "components/Home/Footer";

export default function HomeBody() {
  const { isBusinessMode } = useContext(HomepageContext);

  return (
    <>
      <Hero isBusinessMode={isBusinessMode} />
      <Partners />
      <Advantages isBusinessMode={isBusinessMode}/>
      <Features isBusinessMode={isBusinessMode} />
      {/*
        <Pricing />
      */}
      <Footer />
    </>
  );
}
