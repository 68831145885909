import { Card, Col, Container, Row } from "react-bootstrap";
import { motion } from "framer-motion";
import {AiOutlineStock} from "react-icons/ai";
import {RiSecurePaymentLine} from "react-icons/ri";
import {MdOutlineManageAccounts} from "react-icons/md";
import {FaExchangeAlt} from "react-icons/fa";
import {GiTakeMyMoney, GiExpense} from "react-icons/gi";
import {FcCurrencyExchange} from "react-icons/fc";
import {HiReceiptTax} from "react-icons/hi";

export default function Features({ isBusinessMode }) {

  const BusinessFeatures = [
    { id: 1,
      title: "CRYPTO PAYROLL AND BENEFITS",
      icon: <RiSecurePaymentLine className = "w-25 h-25" style = {{color: "#0d6efd"}}/>,
      description: " Our core mission is to provide our customers with the best possible service and set the standard for industry excellence in the crypto payroll and benefits space."
    },
    {id: 2,
      title: "Invoicing",
      icon: <AiOutlineStock className = "w-25 h-25" style = {{color: "#0d6efd"}}/>,
      description: "For employers and financial institutions, Trust Finance offers a range of invoice solutions to suit your business. Our sales team will help you choose the solution that's right for you."
    },
    {id: 3,
      title: "Expense Management",
      icon: <GiExpense className = "w-25 h-25" style = {{color: "#0d6efd"}}/>,
      description: "The Expense Management module is a complete end-to-end solution for managing your expenses so they are personal and accurate as possible."
    },
    {id: 4,
      title: "Accounting",
      icon: <MdOutlineManageAccounts className = "w-25 h-25" style = {{color: "#0d6efd"}}/>,
      description: "Trust Finance provides accounting and financial services for a range of businesses"
    },
  ];

  const PersonalFeatures = [
    { id: 1,
      title: "Wage on Demand",
      icon: <FcCurrencyExchange className = "w-25 h-25" style = {{color: "#0d6efd"}}/>,
      description: " With our Wage on Demand service, you no longer need to be short on cash when you need it. We offer you the ability to find temporary or permanent work in your area, whenever and wherever it is needed.",
    },
    {id: 2,
      title: "Staking and Rewards",
      icon: <GiTakeMyMoney className = "w-25 h-25" style = {{color: "#0d6efd"}} />,
      description: "All our staking programs are designed to help you obtain rewards on your investment.",
    },
    {id: 3,
      title: "Milestone Invoicing",
      icon: <FaExchangeAlt className = "w-25 h-25" style = {{color: "#0d6efd"}} />,
      description: "Milestone-based invoicing to get paid based on achieving project milestones.",
    },
    {id: 4,
      title: "Tax Reporting",
      icon: <HiReceiptTax className = "w-25 h-25" style = {{color: "#0d6efd"}} />,
      description: "File taxes without having to manually track all transaction records.",
    },
  ];

  return (
    <div className="">
      <Container className="text-center p-4">
        <Row className="text-center">
          <Col>
            <p className="h1 text-light p-5 display-3" style={{fontWeight:"800"}}>Product Features</p>
          </Col>
        </Row>
        <Row className="d-flex px-3">
          {
          isBusinessMode ?
          BusinessFeatures.map((features) => (
            <Col md={3} sm={12} key={features.id}>
              <motion.div className ="w-100 h-100 rounded p-2" whileHover={{scale: 1.1, textShadow: "0px 0px 8px rgb(255, 255, 255)", boxShadow: "3px 3px 8px" }}>
              <Card className="border-0 h-100">
                <Card.Body>
                  <Card.Title className="mb-4 px-2">{features.title.toUpperCase()}</Card.Title>
                  {features.icon}
                  <Card.Text className="p-3">
                  {features.description}
                  </Card.Text>
                </Card.Body>
              </Card>
              </motion.div>
            </Col>
          )) :
          PersonalFeatures.map((features) => (
            <Col md={3} sm={12} key={features.id}>
              <motion.div className ="w-100 h-100 rounded p-2" whileHover={{scale: 1.1, textShadow: "0px 0px 8px rgb(255, 255, 255)", boxShadow: "3px 3px 8px" }}>
              <Card className="border-0 h-100">
                <Card.Body>
                  <Card.Title className="mb-4 px-2">{features.title.toUpperCase()}</Card.Title>
                  {features.icon} 
                  <Card.Text className="p-3">
                  {features.description}
                  </Card.Text>
                </Card.Body>
              </Card>
              </motion.div>
            </Col>
            ))
          }
        </Row>
      </Container>
    </div>
  );
}