import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { USERS } from "utils/constants";
import { api } from "./apiRoutes";

export interface WalletInterface {
  data: {
    data: {
      createdAt: Date;
      id: string;
      lastVisited: Date;
      numberOfVisits: number;
      walletAddress: string;
      walletId: string | null;
    };
  };
}

export interface PersonalRegistration {
  emailAddress: string;
  name: string;
  userType: string;
}

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl:
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_SERVER_ENDPOINT
        : "https://trust-api.vercel.app",
  }),
  endpoints: (builder) => ({
    connectUserWallet: builder.mutation<WalletInterface, string>({
      query: (walletAddress) => ({
        url: api.connect,
        method: "POST",
        body: {
          walletAddress,
        },
      }),
    }),
    registerPersonalUser: builder.mutation({
      query: ({ emailAddress, name, walletAddress, userType = USERS.PERSONAL }) => ({
        url: api.signup,
        method: "POST",
        body: {
          emailAddress,
          name,
          userType,
          walletAddress,
        },
      }),
    }),
    registerBusinessUser: builder.mutation({
      query: ({
        emailAddress,
        name,
        walletAddress,
        businessName,
        paymentType,
        role,
        userType = USERS.BUSINESS,
      }) => ({
        url: api.signup,
        method: "POST",
        body: {
          emailAddress,
          name,
          userType,
          walletAddress,
          businessName,
          paymentType,
          role,
        },
      }),
    }),
    doTest: builder.query({
      query: () => ({
        url: "/auth/123",
      }),
    }),
  }),
});

export const {
  useConnectUserWalletMutation,
  useDoTestQuery,
  useRegisterPersonalUserMutation,
  useRegisterBusinessUserMutation,
} = authApi;
