import { Col, Row } from "react-bootstrap";
import TrustFinanceLogo from "../../assets/newlogo.png";

const Footer = () => {
  return (
    <div className="w-100 text-white mt-5" style ={{backgroundColor: "#202A44"}}>
        <Row className="px-5 py-3 align-items-center text-center ">
            <Col>
                <div className="py-2">
                <img
            src={TrustFinanceLogo}
            width={60}
            height={60}
            alt="trust fe logo"
            className="mr-0 align-items-center justify-content-center mt-2"
          />
                </div>
                <h5 style = {{fontWeight:"900"}}>TRUST FINANCE</h5>
                
            </Col>
            <Col className="mt-3">
                <h5 style ={{fontWeight:"900"}} className = "ms-4">
                    Documentation
                </h5>
                <ul style={{listStyleType:"None"}}>
                    <li ><a href="https://product-dao.gitbook.io/trust-finance/" target="_blank" rel="noopener noreferrer" className="text-decoration-none">Get Started</a></li>
                    <li><a href="https://product-dao.gitbook.io/trust-finance/guides/business-user" target="_blank" rel="noopener noreferrer" className="text-decoration-none">Business</a></li>
                    <li><a href="https://product-dao.gitbook.io/trust-finance/guides/personal-users" target="_blank" rel="noopener noreferrer" className="text-decoration-none">Personal</a></li>
                </ul>
            </Col>
            <Col className="mt-3">
                <h5 style ={{fontWeight:"900"}} className = "ms-4">Community</h5>
                <ul style={{listStyleType:"None"}}>
                    <li><a href="https://discord.gg/K9hN9kFC" target="_blank" rel="noopener noreferrer" className="text-decoration-none">Discord</a></li>
                    <li><a href="https://twitter.com/trust_finance_" target="_blank" rel="noopener noreferrer" className="text-decoration-none">Twitter</a></li>
                    <li><a href="https://www.linkedin.com/company/trust-finance-xyz/" target="_blank" rel="noopener noreferrer" className="text-decoration-none">LinkedIn</a></li>
                </ul>
            </Col>
        </Row>
        <Row className="text-center">
            <p className="blockquote-footer"> Copyright © 2022 Trust Finance  </p>
        </Row>
    </div>
  );
};

export default Footer;