import { Button, Modal } from "react-bootstrap";

const TransactionLabels = ({show, handleClose, showDetails}) => {

    function handleCheckboxChange(label, event){
        label.checked = !label.checked;
        let newArray = [...showDetails.Labels, event.target.id];
        if (showDetails.Labels.includes(event.target.id)) {
          newArray = newArray.filter(label => label !== event.target.id);
        }
        showDetails.Labels = newArray;
        console.log(showDetails);
      };

      function checkChecked(label){
        if (showDetails.Labels.includes(label.name)){
            label.checked = true;
            return label.checked;
        }
        else{
          label.checked = false;
          return label.checked;
        }
      }

      const Labels = [
        {
          name: "Staking",
          checked: false
        },
        {
          name: "Mining",
          checked: false
        },
        {
          name: "Lending",
          checked: false
        },
        {
          name: "DeFi",
          checked: false
        },
        {
          name: "NFT",
          checked: false
        },
        {
          name: "Exchange",
          checked: false
        },
        {
          name: "Wallet",
          checked: false
        },
        {
          name: "Airdrop",
          checked: false
        }
      ];

  return (
    <div>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-primary" style={{fontWeight: "800"}}>Labels</Modal.Title>
        </Modal.Header>
        <Modal.Body>
           { 
           Labels.map((label, index) => (
                <div className="" key={index}>
                <input
                type="checkbox"
                className=""
                id= {label.name}
                value={label.name}
                defaultChecked={checkChecked(label)}
                onChange={() => handleCheckboxChange(label, event)}  
              />
              <label className="mx-2" htmlFor={label.name}>
                 { label.name}
              </label>
       </div>
                
            ))
            }


        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TransactionLabels;