// import { Link } from "react-router-dom";

export default function BusinessNav() {
  return (
    <nav className="navbar navbar-expand-lg bg-white py-3 sticky-top">
      <div className="container-fluid">
        <div className="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
          {/* <Link to="/personal/dashboard">
            <button className="btn btn-primary">
              Go to Personal Dashboard
            </button>
          </Link> */}
        </div>
      </div>
    </nav>
  );
}
