import { useCallback, useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import LoadingButton from "components/LoadingButton";
import TransactionTables from "components/Tables/TransactionTables";
import { BsPencilSquare, BsXLg } from "react-icons/bs";
import { BigNumber, ethers } from "ethers";
import { isuperFluid, isuperToken, nativeCompany, trustIDA, trustMain } from "utils/helpers";
import {
  CFA_ADDRESS,
  HOST_ADDRESS,
  NATIVE_COMPANY_ADDRESSES,
  TEST_TOKEN_ADDRESS,
  TRUST_IDA_ADDRESSES,
  TRUST_MAIN_ADDRESSES,
} from "utils/addresses";

import { useSigner, useAccount, useNetwork } from "wagmi";
import { useStreamActive } from "hooks/useStreamActive";
import toast from "react-hot-toast";
import { useAllowance } from "hooks/useAllowance";

import { useBalance } from "hooks/useBalance";
import { useDispatch } from "react-redux";
import { setFixed, setPeriodic, setStream } from "reducers/transactions/transactionsSlice";
// import cfaAbi from "../../../utils/CFA.json";
// import web3 from "web3";

// import { useTransactions } from "store";

type PaymentProps = {
  paymentType: string;
};

const PAYMENT_FIELDS = [
  // {
  //   title: "Network",
  //   name: "network",
  //   placeholder: "Polygon",
  // },
  {
    title: "Wallet Address",
    name: "walletAddress",
  },
  {
    title: "Amount",
    name: "amount",
    placeholder: "USDC",
  },
  {
    title: "Reason",
    name: "reason",
  },
];

export type UserInput = {
  network: string;
  walletAddress: string;
  amount: number | string | null;
  reason: string;
  acknowledge: boolean;
  frequency?: string;
  startDate?: Date | string;
  endDate?: Date | string;
  paymentType?: string;
};

export default function PaymentForm({ paymentType }: PaymentProps) {
  const dispatch = useDispatch();
  const [payments, setPayments] = useState<UserInput[]>([]);
  const [totalAmount, settotalAmount] = useState<number>();
  const [reload, setreload] = useState<boolean>(false);
  const { register, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      network: "",
      walletAddress: "",
      amount: null,
      reason: "",
      acknowledge: false,
      frequency: "",
      startDate: new Date().toISOString().split("T")[0],
      endDate: new Date().toISOString().split("T")[0],
      paymentType,
    },
  });
  // const { chainId, library } = useWeb3React();

  const signer = useSigner();
  const { address: account } = useAccount();
  const { chain } = useNetwork();

  useStreamActive();
  const { allowance: trustidaallowance } = useAllowance(
    TRUST_IDA_ADDRESSES[chain?.id as number],
    reload,
    setreload,
  );
  const { balance } = useBalance();

  console.log("balance", balance);

  const addEmployee = useCallback(
    (data: UserInput) => {
      setPayments([...payments, data]);
      reset();
    },
    [payments, reset],
  );

  const deleteUser = useCallback(
    (data: UserInput) => {
      const removed = payments.filter((address) => address.walletAddress !== data.walletAddress);
      setPayments(removed);
    },
    [payments],
  );

  const editUser = useCallback(
    (data) => {
      const toEdit = payments.find((address) => address.walletAddress === data.walletAddress);
      deleteUser(data);
      setValue("network", toEdit?.network as string);
      setValue("walletAddress", toEdit?.walletAddress as string);
      setValue("amount", toEdit?.amount as any);
      setValue("reason", toEdit?.reason as string);
      setValue("frequency", toEdit?.frequency as string);
    },
    [payments, deleteUser, setValue],
  );

  console.log("signer", signer.data);

  const getAddresses = (array: UserInput[]) => {
    const addresses: string[] = [];

    for (let i = 0; i < array.length; i++) {
      addresses.push(array[i].walletAddress);
    }

    return addresses;
  };

  const getAmount = (array: UserInput[]) => {
    const amount: ethers.BigNumber[] = [];

    for (let i = 0; i < array.length; i++) {
      const formatted = ethers.utils.parseEther((array[i].amount as number).toString());
      amount.push(formatted);
    }

    return amount;
  };

  const getTotalAmount = (array: UserInput[]) => {
    if (array) {
      const amount: number[] = [];

      for (let i = 0; i < array.length; i++) {
        const formatted = parseFloat(array[i].amount as string);
        amount.push(formatted);
      }

      return amount?.reduce((a, b) => a + b);
    }
  };

  console.log(trustidaallowance);

  const getFlowRate = (array: UserInput[]) => {
    let sum = 0;

    for (let i = 0; i < array.length; i++) {
      sum += array[i].amount as number;
    }

    return ethers.utils.parseEther((sum / 2592000).toFixed(18));
  };

  const getDeadlineAndIntervals = (array: UserInput[]) => {
    const time: number[] = [];

    for (let i = 0; i < array.length; i++) {
      time.push(2592000); //30 days in seconds hardcoded value
    }
    return time;
  };

  const getTimeForFixed = (array: UserInput[]) => {
    const time: number[] = [];

    for (let i = 0; i < array.length; i++) {
      time.push(0); //30 days in seconds hardcoded value
    }
    return time;
  };

  useEffect(() => {
    if (payments.length !== 0) {
      const amounts = getTotalAmount(payments);
      settotalAmount(amounts);
      console.log(amounts);
    }
  }, [payments]);

  const handleStartStream = useCallback(async () => {
    try {
      const amounts = getAmount(payments);
      const addresses = getAddresses(payments);
      const time = getDeadlineAndIntervals(payments);
      const flowRate = getFlowRate(payments);

      const nativeCompanyContract = nativeCompany(
        NATIVE_COMPANY_ADDRESSES[chain?.id as number],
        signer.data,
      );

      const callData = await nativeCompanyContract.getCallData(
        TEST_TOKEN_ADDRESS[chain?.id as number],
        flowRate,
      );

      const isuperFluidContract = isuperFluid(HOST_ADDRESS[chain?.id as number], signer.data);

      const userData = await nativeCompanyContract.getuserData(addresses, amounts, time, time);

      const callTx = isuperFluidContract.callAgreement(
        CFA_ADDRESS[chain?.id as number],
        callData,
        userData,
        {
          from: account,
        },
      );

      const { confirmations } = await callTx.wait(1);

      if (confirmations >= 1) {
        console.log("done");
        payments.forEach((payment) => dispatch(setStream(payment)));
      }
    } catch (err) {
      console.log(err);
    }
  }, [payments, chain?.id, signer.data, account, dispatch]);

  // const handleStartStream = async () => {

  // };

  // const addCompany = async (index: number) => {
  //   try {
  //     const trustmainContract = trustMain(TRUST_MAIN_ADDRESSES[chainId as number], library);
  //     const createCompanyTx = await trustmainContract._createCompany(index, {
  //       from: account,
  //     });

  //     const { confirmations } = await createCompanyTx.wait(1);

  //     if (confirmations >= 1) {
  //       console.log(createCompanyTx);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const addEmployees = useCallback(
    async (data: UserInput) => {
      let frequency = 2592000;

      if (data.frequency) {
        frequency = parseInt(data.frequency, 10) * 60 * 60 * 24;
      }

      try {
        const trustmainContract = trustMain(TRUST_MAIN_ADDRESSES[chain?.id as number], signer.data);

        console.log("trustmainContract :>> ", trustmainContract);

        const addEmployeeTx = await trustmainContract.addEmployee(
          data.walletAddress,
          account,
          TEST_TOKEN_ADDRESS[chain?.id as number],
          ethers.utils.parseEther((data.amount as number).toString()),
          frequency,
          2592000,
          1,
          {
            from: account,
          },
        );

        const { confirmations } = await addEmployeeTx.wait(1);

        if (confirmations > 1) {
          console.log(confirmations);
        }

        setPayments([...payments, data]);
        reset();
      } catch (err) {
        console.log(err);
      }
    },
    [chain?.id, signer.data, account, payments, reset],
  );

  const addRecurring = useCallback(
    async (data: UserInput) => {
      console.log(data);
      addEmployees(data);
      // handleSubmit(addEmployee);

      // await approveTokens();
      // addCompany();
    },
    [addEmployees],
  );

  const getPeriodicAmounts = useCallback(
    async (employees: any) => {
      const amounts: any[] = [];
      const trustmainContract = trustMain(TRUST_MAIN_ADDRESSES[chain?.id as number], signer.data);

      for (let i = 0; i < employees.length; i++) {
        const employeeInfo = await trustmainContract.viewEmployeeInfo(employees[i]);

        amounts.push(employeeInfo.monthlyIncome);
      }

      return amounts;
    },
    [chain?.id, signer.data],
  );

  const handlePeriodicPayment = useCallback(async () => {
    try {
      const trustmainContract = trustMain(TRUST_MAIN_ADDRESSES[chain?.id as number], signer.data);

      const employees = await trustmainContract.viewCompanyEmployees(account);

      // const employeeInfo = await trustmainContract.viewEmployeeInfo(employees[0]);
      const amounts = await getPeriodicAmounts(employees);

      const makePeriodicPaymenttx = await trustmainContract.makePeriodicPayment(
        employees,
        amounts,
        {
          from: account,
        },
      );

      const { confirmations } = await makePeriodicPaymenttx.wait();

      if (confirmations >= 1) {
        console.log("done");
        payments.forEach((payment) => dispatch(setPeriodic(payment)));
      }
    } catch (err: any) {
      toast.error(err?.data?.message);
      console.log(err);
    }
  }, [account, chain?.id, dispatch, getPeriodicAmounts, payments, signer.data]);

  const approveTokens = useCallback(
    async (contractAddress: string) => {
      try {
        const token = isuperToken(TEST_TOKEN_ADDRESS[chain?.id as number], signer.data);

        const approveAmount = (totalAmount as number) * 10;

        const approveTx = await token.approve(
          contractAddress,
          ethers.utils.parseEther(approveAmount.toString()),
          {
            from: account,
          },
        );

        const { confirmations } = await approveTx.wait();

        if (confirmations >= 1) {
          setreload(true);
          toast.success("Approval Successful");
          console.log("done");
        }
      } catch (err) {
        toast.error("An error occurred");
        console.log(err);
      }
    },
    [account, chain?.id, signer.data, totalAmount],
  );

  const handleFixedPayment = useCallback(async () => {
    try {
      const amounts = getAmount(payments);
      const addresses = getAddresses(payments);
      const time = getTimeForFixed(payments);
      const trustIda = trustIDA(TRUST_IDA_ADDRESSES[chain?.id as number], signer.data);

      console.log(amounts);

      const paymentTx = await trustIda.makeOnetimebatch(addresses, amounts, time);
      const { confirmations } = await paymentTx.wait();
      console.log(payments);
      if (confirmations >= 1) {
        toast.success("Transaction Successful");
        payments.forEach((payment) => dispatch(setFixed(payment)));
      }
    } catch (err) {
      console.log(err);
      toast.error("An error occurred");
    }
  }, [chain?.id, signer.data, payments, dispatch]);

  const sendPayment = useCallback(() => {
    if (paymentType === "fixed") {
      if ((totalAmount as number) > parseFloat(trustidaallowance as string)) {
        console.log("approving");
        approveTokens(TRUST_IDA_ADDRESSES[chain?.id as number]);
      } else {
        console.log("continue");
        handleFixedPayment();
        // setFixed(payments);
      }
    }
    if (paymentType === "recurring") {
      handlePeriodicPayment();
      // addEmployees()
      // addPeriodicPaymentCompany();
      // handlePeriodicPayment()
    }
    if (paymentType === "stream") {
      setStream(payments);
      handleStartStream();
      // addCompany();
    }
  }, [
    paymentType,
    payments,
    handleStartStream,
    handlePeriodicPayment,
    totalAmount,
    trustidaallowance,
    chain?.id,
    approveTokens,
    handleFixedPayment,
  ]);

  console.log(totalAmount);

  return (
    <>
      <div>
        <Row>
          {paymentType === "fixed" ? (
            <>
              <Col md={4}>
                <Form.Group className="mb-3" controlId="payment date">
                  <Form.Label>Payment Date</Form.Label>
                  <Form.Control
                    type="date"
                    {...register("startDate", {
                      required: {
                        message: "Date is required",
                        value: true,
                      },
                    })}
                    placeholder="DD/MM/YYYY"
                  />
                </Form.Group>
              </Col>
              <Col md={2}></Col>
            </>
          ) : (
            <div className="d-flex justify-content-between w-50 gap-4">
              <Col md={6}>
                <Form.Group className="mb-3" controlId="startDate">
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    type="date"
                    {...register("startDate", {
                      required: {
                        message: "Date is required",
                        value: true,
                      },
                    })}
                    placeholder="DD/MM/YYYY"
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="endDate">
                  <Form.Label>End Date</Form.Label>
                  <Form.Control
                    type="date"
                    {...register("endDate", {
                      required: {
                        message: "Date is required",
                        value: true,
                      },
                    })}
                    placeholder="DD/MM/YYYY"
                  />
                </Form.Group>
              </Col>
            </div>
          )}
          <Col md={6}>
            <div className="d-flex justify-content-end gap-3 mt-4 pt-2 me-4 ms-auto">
              <LoadingButton
                disabled={!payments.length}
                buttonText={
                  paymentType === "fixed" && (totalAmount as number) > parseFloat(balance as string)
                    ? "Insufficient Balance"
                    : paymentType === "fixed" &&
                      (totalAmount as number) > parseFloat(trustidaallowance as string)
                    ? "Approve tokens to continue"
                    : "Send Payment"
                }
                isLoading={false}
                handleClick={sendPayment}
              />
            </div>
          </Col>
        </Row>
        <Card>
          <Card.Body className="p-4 payment_card">
            <Row>
              <Col md={8}>
                {paymentType === "recurring" && (
                  <Form.Group
                    className="mb-3 d-flex flex-row align-items-center"
                    controlId="frequency"
                  >
                    <Form.Label className="mb-0">Frequency</Form.Label>
                    <div className="ms-4">
                      <div className="d-flex justify-content-start align-items-center ms-5 ps-5">
                        <div>Every</div>
                        <Form.Control
                          type="number"
                          className="w-75 mx-4"
                          {...register("frequency", { required: true })}
                          placeholder="1"
                        />
                        <div>Days</div>
                      </div>
                    </div>
                  </Form.Group>
                )}
                {PAYMENT_FIELDS.map((field) => (
                  <Form.Group
                    className="mb-3 d-flex flex-row justify-content-between align-items-center"
                    key={field.title}
                    controlId={field.title}
                  >
                    <Form.Label className="mb-0">{field.title}</Form.Label>
                    <Form.Control
                      type="text"
                      className="w-75"
                      placeholder={field.placeholder}
                      // @ts-ignore
                      {...register(field.name, { required: true })}
                    />
                  </Form.Group>
                ))}
                <Form.Group
                  className="mb-3 d-flex flex-row justify-content-between align-items-center"
                  controlId="acknowledge"
                >
                  <Form.Label className="mb-0">Acknowledge</Form.Label>
                  <Form.Text id="passwordHelpBlock" className="w-75 d-flex">
                    <Form.Check className="me-3" type="checkbox" {...register("acknowledge")} />
                    Please make sure you enter the correct withdrawal address of the transfer
                    network you selected. Withdrawal order cannot be cancelled after creation.
                  </Form.Text>
                </Form.Group>

                <Row className="mt-4">
                  <Col md={12}>
                    <div className="d-flex gap-3 justify-content-end">
                      {/* @ts-ignore */}
                      <Button
                        variant="outline-primary"
                        onClick={
                          paymentType === "recurring"
                            ? handleSubmit(addRecurring)
                            : handleSubmit(addEmployee)
                        }
                      >
                        Add Recepient
                      </Button>
                      {/* <Button variant="primary">Create Payment</Button> */}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={4} className="border border-1 rounded-2">
                <div className="overflow-auto h-100 py-3 address_card">
                  {payments?.map((address: any) => (
                    <Card key={address.walletAddress} className="p-3 mb-3">
                      <div className="d-flex gap-4 justify-content-end mb-2">
                        <span onClick={() => editUser(address)}>
                          <BsPencilSquare className="text-info cursor_pointer" />
                        </span>
                        <span onClick={() => deleteUser(address)}>
                          <BsXLg className="text-danger cursor_pointer" />
                        </span>
                      </div>
                      <p className="mb-0">Address: {address.walletAddress}</p>
                      <p className="mb-0">Amount: {address.amount}</p>
                    </Card>
                  ))}
                  {!payments.length && (
                    <div className="d-flex justify-content-center align-items-center">
                      <p className="text-muted">Saved employee information will show here</p>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
      <div className="mt-4">
        <Card>
          <Card.Body>
            <TransactionTables type={paymentType} />
          </Card.Body>
        </Card>
      </div>
    </>
  );
}
