export enum USERS {
  BUSINESS = "business",
  PERSONAL = "personal",
}

export enum PATHS {
  REGISTRATION = "/registration",
  LOGIN = "/login",
  BUSINESS_REGISTRATION = "/business-registration",
  PERSONAL_REGISTRATION = "/personal-registration",
  ADMIN_DASHBOARD = "/admin/*",
  PERSONAL_DASHBOARD = "/personal/*",
  BUSINESS_DASHBOARD = "/business/*",
  DASHBOARD = "/dashboard",
  BETA_SIGNUP = "/sign-up",
  SECRET_PAGE = "/super-secret-page",
}

export enum SupportedChainId {
  POLYGON = 137,
  BINANCE = 56,
  BINANCETEST = 97,
  POLYGONTEST = 80001,
}

//supported chain ID ARRAY
export const ALL_SUPPORTED_CHAIN_IDS: SupportedChainId[] = [
  SupportedChainId.BINANCE,
  SupportedChainId.POLYGON,
  SupportedChainId.POLYGONTEST,
  SupportedChainId.BINANCETEST,
];

export const TEST_TOKEN_ADDRESS = "0x983E2b60604603792ea99b95f5e69A0445a83278";

export const REDUX_SECRET_KEY = "my-super-secret-key";
