import Layout from "components/Layout/Layout";
import PersonalAppWrapper from "./routes";

export default function index() {
  return (
    <Layout presentLocation="/personal">
      <PersonalAppWrapper />
    </Layout>
  );
}
