import Dashboard from "./pages/Dashboard";
import MobileNavbar from "../../components/Navbar";
import { Swiper, SwiperSlide } from "swiper/react";
import Invoice from "./pages/Invoice";
import CreateInvoice from "./pages/InvoiceCreate";
import IncomeYieldandLoan from "./pages/IncomeYieldandLoan";




export default function MobileHome() {
  return (
    <>
      <MobileNavbar />
      <Swiper className="mySwiper">
        <SwiperSlide><Dashboard /></SwiperSlide>
        <SwiperSlide>Invest</SwiperSlide>
        <SwiperSlide><IncomeYieldandLoan /></SwiperSlide>
        <SwiperSlide><CreateInvoice /></SwiperSlide>
        <SwiperSlide><Invoice /></SwiperSlide>
      </Swiper>
      
    </>
  );
}
