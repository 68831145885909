import { nanoid } from "@reduxjs/toolkit";
import useBoolean from "hooks/useBoolean";
import { Button, Dropdown, DropdownButton, Form, InputGroup, Table } from "react-bootstrap";
import { BusinessInvoiceList } from "utils/dummyData";
import CreateInvoice from "./CreateInvoice";

type InvoiceProps = {
  source: string;
};

export default function InvoiceTable({ source }: InvoiceProps) {
  const [createInvoice, setCreateInvoice] = useBoolean(false);

  return (
    <section className="m-4 px-3">
      <p className="h4">Invoice</p>
      <div className="d-flex justify-content-between align-items-center my-4">
        <div>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1" className="bg-white">
              <i className="bi bi-search"></i>
            </InputGroup.Text>
            <Form.Control placeholder="Search by wallet nickname" aria-label="Search" />
          </InputGroup>
        </div>
        {source === "business" && (
          <Button className="py-1" onClick={() => setCreateInvoice.on()}>
            Create New Invoice
          </Button>
        )}
      </div>
      <div>
        <Table hover responsive>
          <thead>
            <tr>
              <th>S/No</th>
              <th>Clients Address</th>
              <th>Amount</th>
              <th>Token</th>
              <th>Due Date</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {BusinessInvoiceList.map((detail) => (
              <tr key={nanoid()}>
                <td className="align-middle py-3">{detail.id}</td>
                <td className="align-middle py-3">{detail.clientsAddress}</td>
                <td className="align-middle py-3">{detail.amount}</td>
                <td className="align-middle py-3">{detail.token}</td>
                <td className="align-middle py-3">{detail.due}</td>
                <td className="align-middle py-3">{detail.status}</td>
                <td className="align-middle py-3">
                  <DropdownButton
                    id="dropdown-basic-button"
                    title=""
                    variant="link"
                    className="p-0"
                  >
                    <Dropdown.Item href="#/action-1">View</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Make Payment</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Negotiate</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Decline</Dropdown.Item>
                  </DropdownButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <CreateInvoice show={createInvoice} onHide={() => setCreateInvoice.off()} />
    </section>
  );
}
