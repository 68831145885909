export default function LoadingOverlay() {
  return (
    <div className="loading_overlay">
      <div className="ring_parent">
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
}
