import { RootState } from "app/store";
import { createSlice } from "@reduxjs/toolkit";

export interface ILayoutState {
  sidebar: boolean | null;
  showFullLoader: boolean;
  mintModal: boolean;
}

const initialState: ILayoutState = {
  showFullLoader: false,
  sidebar: null,
  mintModal: false,
};

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    toggleSidebar: (state: ILayoutState) => {
      state.sidebar = !state.sidebar;
    },
    showSidebar: (state: ILayoutState) => {
      state.sidebar = true;
    },
    hideSidebar: (state: ILayoutState) => {
      state.sidebar = false;
    },
    setFullPageLoader: (state, { payload }) => {
      state.showFullLoader = payload;
    },
    viewMintModal: (state, { payload }) => {
      state.mintModal = payload;
    },
  },
});

export const { toggleSidebar, showSidebar, hideSidebar, setFullPageLoader, viewMintModal } =
  layoutSlice.actions;

export const selectLayout = (state: RootState) => state.layout;

export default layoutSlice.reducer;
