import { useState } from "react";
import { Link } from "react-router-dom";
import { Card, Col, Row } from "react-bootstrap";

import MetricsCards from "./components/MetricsCards";
import PaymentPlan from "./components/DashboardTransaction";
import DashboardChart from "pages/business/components/charts/DashboardChart";
import { BsPersonCircle } from "react-icons/bs";
import { AiOutlineArrowRight } from "react-icons/ai";

// import CreateInvoice from "../../components/Invoice/CreateInvoice";
import { useBalance } from "hooks/useBalance";
import { paymentData } from "utils/dummyData";

export default function BusinessHome() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [numberOfEmployees, setNumberOfEmployees] = useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [moneyIn, setMoneyIn] = useState<string>("");
  const { balance, symbol } = useBalance();

  return (
    <>
      <Row>
        <MetricsCards moneyIn={balance} symbol={symbol} />
        {/* SEND MONEY */}
        <Row className="mt-3" moneyIn={moneyIn}>
          <Col md={5}>
            <Card className="border-0 card_shadow shadow h-100">
              <Card.Body>
                <Card.Subtitle className="py-3 text-primary mx-2 d-flex justify-content-between">
                  <div>Financials</div>
                  <div className="">
                    <a href="" className="text-decoration-none">
                      View Details <AiOutlineArrowRight />
                    </a>
                  </div>
                </Card.Subtitle>
                <DashboardChart />
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="border-0 card_shadow shadow h-100">
              <Card.Body>
                <Card.Subtitle className="py-3 text-primary mx-2 d-flex justify-content-between">
                  <div>Upcoming Payments</div>
                  <Link to="/payments" className="text-decoration-none">
                    <div className="">
                      <span className="text-decoration-none">
                        View All <AiOutlineArrowRight />
                      </span>
                    </div>
                  </Link>
                </Card.Subtitle>
                <Col className="w-100  h-100">
                  {paymentData.length === 0 ? (
                    <div className="h-100">
                      <p className="text-muted items-center text-center justify-content-center mt-5 p-">
                        Great Job! No Upcoming Payments
                      </p>
                    </div>
                  ) : (
                    paymentData.map((item) => (
                      <Row className="p-1" key={item.id}>
                        <Col md={8} className="d-flex items-center">
                          <BsPersonCircle size={30} />
                          <div className="">
                            <p
                              className="float-left px-2 fw-bold mb-0"
                              style={{ fontSize: "small" }}
                            >
                              {item.remark.length >= 18
                                ? item.remark.substring(0, 18) + "..."
                                : item.remark}
                            </p>
                            <p className="text-muted px-2" style={{ fontSize: "x-small" }}>
                              {item.date}
                            </p>
                          </div>
                        </Col>
                        <Col>
                          <div className="">
                            {item.amount} {item.coin}
                          </div>
                        </Col>
                      </Row>
                    ))
                  )}
                </Col>
              </Card.Body>
            </Card>
          </Col>
          <Col>{/* <CreateInvoice /> */}</Col>
        </Row>
        {/* CREATE AN INVOICE  */}
        <div className="row mt-4">
          <PaymentPlan numberOfEmployees={numberOfEmployees} />
        </div>
        <Col></Col>
      </Row>
    </>
  );
}
