import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "styles/index.scss";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { store } from "app/store";
import ErrorBoundary from "hoc/ErrorBoundary";
import { Toaster } from "react-hot-toast";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { wagmiClient } from "utils/providers";
import { WagmiConfig } from "wagmi";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const persistor = persistStore(store);

root.render(
  <React.StrictMode>
    <WagmiConfig client={wagmiClient}>
      <ErrorBoundary>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <>
                <App />
                <Toaster position="top-right" />
              </>
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </ErrorBoundary>
    </WagmiConfig>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
