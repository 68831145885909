import React from "react";
import ReactApexChart from "react-apexcharts";

class AvailablePiechart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            series: [34, 66],
            options: {
                colors: ["#D3D3D3", "#0592f0"],
                chart: {
                    width: 380,
                    type: "pie",
                },
                legend: {
                    show: false,
                },
                dataLabels: {
                    enabled: false
                },
                labels: ["Utilised", "Available"],
                
            },


        };
    }



    render() {
        return (


            <div id="chart" className=''>
              
                <div className='align-items-center'>
                    <div>
                        <ReactApexChart options={this.state.options} series={this.state.series} type="pie" width={100} />
                    </div>
                    <div className=''>
                        <p className=''>Projected Payroll <span className=''>$10,000 </span></p>
                    </div>
                </div>
            </div>

        );
    }
}

export default AvailablePiechart;