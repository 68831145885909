import { useAppDispatch, useAppSelector } from "app/hooks";
import { ReactNode, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import { BsX } from "react-icons/bs";
import { isMobile } from "react-device-detect";
import { hideSidebar, showSidebar, toggleSidebar } from "reducers/layout/layoutSlice";
import { SidebarLinks } from "./sidebarLinks";
import DisconnectButton from "components/DisconnectButton";
import MintModal from "components/Modal/MintModal";
import useBoolean from "hooks/useBoolean";
type Props = {
  children: ReactNode;
  presentLocation: string;
};

export default function Layout({ children, presentLocation }: Props) {
  const { sidebar } = useAppSelector((state) => state.layout);
  const dispatch = useAppDispatch();
  const [showMintModal, setShowMintModal] = useBoolean(false);

  useLayoutEffect(() => {
    isMobile ? dispatch(hideSidebar()) : dispatch(showSidebar());

    return () => {
      dispatch(hideSidebar());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const SidebarItems = ({ link }) => {
    if (link?.exclude?.includes(presentLocation)) return null;
    return (
      <Link to={`/${link.destination}`} key={link.title} className="text-decoration-none">
        <li className="nav-item">
          <p className="nav-link my-2 active" aria-current="page">
            <i className={`me-3 bi ${link.icon}`} />
            {link.title}
          </p>
        </li>
      </Link>
    );
  };

  const closeMintModal = () => {
    setShowMintModal.off();
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  };

  return (
    <div className="d-flex">
      <div
        className={`flex-column flex-shrink-0 p-3 text-white bg-primary sticky-top custom_sidebar ${
          sidebar ? "custom_sidebar-open" : "custom_sidebar-closed"
        }`}
      >
        <div className="d-flex justify-content-between">
          <a
            href="/"
            className="d-flex align-items-center mb-0 mb-md-0 me-md-auto text-white text-decoration-none"
          >
            <span className="fs-4">
              {/* <img src={Logo} width={100} /> */}
              Trust
            </span>
          </a>
          <div onClick={() => dispatch(toggleSidebar())}>{isMobile && <BsX fontSize={24} />}</div>
        </div>
        <hr />
        <ul className="nav nav-pills flex-column mb-auto">
          {SidebarLinks.map((link) => (
            <SidebarItems key={link.title} link={link} />
          ))}
        </ul>
        <div className="text-center faucet_btn" onClick={() => setShowMintModal.on()}>
          <p className="mb-0">🛀 Trust Faucet</p>
        </div>
        <hr />
        <div className="dropdown d-flex align-items-center">
          <img
            src="https://w7.pngwing.com/pngs/831/88/png-transparent-user-profile-computer-icons-user-interface-mystique-miscellaneous-user-interface-design-smile.png"
            alt=""
            width="32"
            height="32"
            className="rounded-circle me-2"
          />
          <div className="flex-row p-2">
            <DisconnectButton />
          </div>
        </div>
      </div>
      <div className={`custom_body ${sidebar ? "custom_body-closed" : "custom_body-full"}`}>
        {children}
      </div>
      <MintModal show={showMintModal} handleClose={closeMintModal} />
    </div>
  );
}
