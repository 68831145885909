import React from "react";
import { Col } from "react-bootstrap";

type Props = {
  value: string;
  type: string;
  usage: string;
  image: string;
};

export default function Metrics({
  value = "0",
  type = "USDC",
  usage,
  image
}: Props) {
  return (
      <Col className="h-100 w-100">
        <div className="card border-0 card_shadow h-100 w-100">
          <div className="card-body text-center ">
            <div>
              <img src={image} />
            </div>
            <div className="">
              <p className="h6 fw-normal text-bold">
                {value} {type}
              </p>
            </div>
            <div>
              <p className="h6 text-muted">
                {usage}
              </p>
            </div>
          </div>
        </div>
      </Col>
  );
}
