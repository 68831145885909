import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { tokenTransaction } from "utils/dummyData";
import TokenTransactionDetails from "./TokenTransactionDetails";

const TokenTransactions = () => {

    const [showSummary, setShowSummary] = useState(-1);
    const [showDetails, setShowDetails] = useState(
        {
            Date: "",
            Token: "",
            Quantity: 0, 
            SendReceive: "",
            Cost: 0,
            Total: 0,
            CurrentPrice: 0,
            absoluteProfitLoss: 0,
            percentageProfitLoss: 0,
        }
    );

    function displayDetails(item, index) {
        setShowSummary(index);
        showDetails.Date = item.Date;
        showDetails.Token = item.Token;
        showDetails.Quantity = item.Quantity;
        showDetails.Cost = item.Amount;
        showDetails.Total = (item.Quantity*item.Amount);
        showDetails.CurrentPrice = item.LTP;
        showDetails.absoluteProfitLoss = (item.LTP - item.Amount) * item.Quantity;
        showDetails.percentageProfitLoss = (((item.LTP - item.Amount) / item.Amount) * 100);
        setShowDetails(showDetails);
    };

    return (
        <div>
             <Col>
            {
                showSummary > -1 ? (<TokenTransactionDetails showDetails={showDetails}/>)
                    : null
            }
            </Col>
            <Col>
            <Container>
                <Row className="text-primary mx-3" width="80%" style={{ fontWeight: "800" }}>
                    <Col>
                        Date
                    </Col>
                    <Col>
                        Token
                    </Col>
                    <Col>
                        Quantity
                    </Col>
                    <Col>
                        Cost
                    </Col>
                    <Col>
                        Total
                    </Col>
                    <Col>
                        Current Price
                    </Col>
                    <Col>
                        Absolute Profit/Loss
                    </Col>
                    <Col>
                        Percentage Profit/Loss
                    </Col>
                    <Col>
                    </Col>
                </Row>
                <Row>
                    {
                        tokenTransaction.map((item, index) => (
                            <Row key={index} className="p-2 border rounded mx-2 my-2">
                                <Col>
                                    {item.Date}
                                </Col>
                                <Col>
                                    {item.Token}
                                </Col>
                                <Col>
                                    {item.Quantity}
                                </Col>
                                <Col>
                                    {item.Amount}
                                </Col>
                                <Col>
                                    {item.Amount * item.Quantity}
                                </Col>
                                <Col>
                                    {item.LTP}
                                </Col>
                                <Col>
                                    {
                                        item.LTP > item.Amount ? (
                                            <span className="text-success">+{(item.LTP - item.Amount) * item.Quantity}</span>
                                        ) : (
                                            <span className="text-danger">{(item.LTP - item.Amount) * item.Quantity}</span>
                                        )}
                                </Col>
                                <Col>
                                    {
                                        item.LTP > item.Amount ? (
                                            <span className="text-success">+{((item.LTP - item.Amount) / item.Amount) * 100}%</span>
                                        ) : (
                                            <span className="text-danger">{((item.LTP - item.Amount) / item.Amount) * 100}%</span>
                                        )}
                                </Col>
                                <Col>
                                    <button className="btn btn-primary" onClick={() => displayDetails(item, index)}>Details</button>
                                </Col>
                            </Row>
                        ))}
                </Row>

            </Container>
            </Col>
        </div>
    );
};

export default TokenTransactions;