import { HomePageWrapper } from "Contexts/HomepageContext";
import HomeBody from "components/Layout/HomeBody";
import HomeNavbar from "components/Layout/HomeNavbar";
import LoadingOverlay from "components/Loaders/LoadingOverlay";
import { useAppSelector } from "app/hooks";

function Home() {
  const { showFullLoader } = useAppSelector((state) => state.layout);
  return (
    <HomePageWrapper>
      {showFullLoader && <LoadingOverlay />}
      <div className="Main_Body">
        <HomeNavbar />
        <HomeBody />
      </div>
    </HomePageWrapper>
  );
}

export default Home;
