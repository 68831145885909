import { useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { Control, FieldValues, useFieldArray, UseFormRegister } from "react-hook-form";
import { BsDashLg, BsPlusLg } from "react-icons/bs";

type AddMilestoneProps = {
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues, any>;
};

export default function AddMilestone({ register, control }: AddMilestoneProps) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  useEffect(() => {
    append({ milestone: "", amount: 0 });
    return () => {
      remove();
    };
  }, [append, remove]);

  return (
    <>
      {fields.map((item, index) => (
        <div className="row mb-3" key={item.id}>
          <div className="col-4">
            <div className="row">
              <Form.Group controlId="address">
                <Form.Label>Milestone {index + 1}</Form.Label>
                <Form.Control
                  type="date"
                  className="form-control"
                  placeholder="Add milestone in days"
                  {...register(`items-${index}-milestone`, {
                    required: {
                      message: "This field is required",
                      value: true,
                    },
                  })}
                />
              </Form.Group>
            </div>
          </div>
          <div className="col-4">
            <Form.Group controlId="amount">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                {...register(`items-${index}-amount`, {
                  required: {
                    message: "This field is required",
                    value: true,
                  },
                })}
              />
            </Form.Group>
          </div>
          <div className="col-4 mt-4 pt-2">
            <Button
              className="me-3"
              variant="outline-success"
              onClick={() => append({ milestone: "", amount: 0 })}
            >
              <BsPlusLg /> Add
            </Button>
            <Button variant="outline-danger" onClick={() => remove(index)}>
              <BsDashLg /> Delete
            </Button>
          </div>
        </div>
      ))}
    </>
  );
}
