import { Button, Col, Row } from "react-bootstrap";

const TokenTransactionDetails = ({showDetails}) => {;

  return (
    <div className="mx-auto p-2 border rounded ps-5 mb-4">
    <Row className="text-primary mx-auto" style={{ fontWeight: "800" }}>
        <p className="h5" style={{fontWeight: "800"}}>Details</p>
    </Row>
    <Row>
    <Col className="text-center justify-content-center align-items-center">
        <Row>
            Date: {showDetails.Date}
        </Row>
        <Row>
            Token: {showDetails.Token}
        </Row>
        <Row>
            Gas Fee: 
        </Row>
    </Col>
    <Col className="text-center justify-content-center align-items-center">
        <Row>
            Cost: {showDetails.Cost}
        </Row>
        <Row>
            Quantity: {showDetails.Quantity}
        </Row>
        <Row>
           Total: {showDetails.Total}
        </Row>
    </Col>
    <Col>
        <Row>
            Current Price: {showDetails.CurrentPrice}
        </Row>
        <Row>
            Absolute P/L: {showDetails.absoluteProfitLoss > 0 ? `+$${showDetails.absoluteProfitLoss}` : `-$${showDetails.absoluteProfitLoss}`}
        </Row>
        <Row>
            Percentage P/L: {showDetails.percentageProfitLoss > 0 ? `+$${showDetails.percentageProfitLoss}` : `-$${showDetails.percentageProfitLoss}`}
        </Row>
    </Col>
    </Row>
    <Row>
        <div className="d-flex mt-4 justify-content-center me-5">
        <Button variant="primary" className="mx-5">Download Token Transaction Report</Button> 
        </div>
    </Row>
</div>
  );
};

export default TokenTransactionDetails;