import { RootState } from "app/store";
import { createSlice } from "@reduxjs/toolkit";

interface AuthState {
  isLoggedIn: boolean;
  authenticatedUserType: string | undefined;
}

const initialState: AuthState = {
  isLoggedIn: false,
  authenticatedUserType: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoggedIn: (state, { payload }) => {
      state.isLoggedIn = payload;
    },
    setAuthenticatedUserType: (state, { payload }) => {
      state.authenticatedUserType = payload;
    },
  },
});

export const { setLoggedIn, setAuthenticatedUserType } = authSlice.actions;

export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
