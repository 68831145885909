import { Container, Navbar } from "react-bootstrap";
import { useAppDispatch } from "app/hooks";
import { BsListNested } from "react-icons/bs";
import { showSidebar, toggleSidebar } from "reducers/layout/layoutSlice";

export default function MobileNavbar() {
  const dispatch = useAppDispatch();
  return (
    <Navbar bg="light" variant="dark">
      <Container className="justify-content-start">
        <BsListNested fontSize={30} color="#0d6efd" onClick={() => dispatch(showSidebar())} />
        <Navbar.Brand
          href="#"
          className="ms-3 text-primary"
          onClick={() => dispatch(toggleSidebar())}
        >
          Trust
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
}
