import DashboardCard from "components/Cards/DashboardCard";
import LoanMarket from "components/LoanMarket";
import PersonalNav from "components/Personal/PersonalNav";
import Metrics from "pages/personal/components/MetricsCards";
import Graph from "pages/personal/components/charts/Graph";
// import CreateInvoice from "components/Invoice/CreateInvoice";
import { useBalance } from "hooks/useBalance";

// import IncomeTable from "components/IncomeTable";
//import AssetTable from "components/AssetTable";

export default function DesktopHome() {
  const { balance, symbol } = useBalance();
  return (
    <>
      <PersonalNav />
      <section className="m-4">
        <div className="row">
          <div className="col-8">
            <div className="row">
              <DashboardCard
                title="Total Income Value"
                indicatorValue="+3.49%"
                value={`${symbol} ${balance}`}
                mode="dark"
              />
              <DashboardCard title="Your returns" indicatorValue="+2.75%" value="0" mode="light" />
            </div>
            <div className="row mt-3">
              <div className="row mt-2">
                <div className="col">
                  <Metrics value="0" type="USDC" usage="Investment" image="" />
                </div>
                <div className="col">
                  <Metrics value="0" type="USDC" usage="Benefit" image="" />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col">
                  <Metrics value="0" type="USDC" usage="Reward" image="" />
                </div>
                <div className="col">
                  <Metrics value="0" type="USDC" usage="Payout" image="" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">{/* <CreateInvoice /> */}</div>
        </div>
        {/* CREATE AN INVOICE  */}
        <div className="row mt-4">
          <div className="col-8">
            <Graph />
          </div>
          <div className="col-4">
            <LoanMarket />
          </div>
        </div>
      </section>
    </>
  );
}
