import { useRoutes } from "react-router-dom";
import BusinessHome from "../Home";
import BusinessWallet from "../Wallet";
import Employees from "../Employees";
import Payment from "../Payment";
import Transactions from "../Transactions";
import ManageEmployees from "../Employees/ManageEmployees";
import Invoice from "../invoice";

export default function BusinessAppWrapper() {
  return useRoutes([
    { path: "/dashboard", element: <BusinessHome /> },
    { path: "/transactions", element: <Transactions /> },
    { path: "/employees", element: <Employees /> },
    { path: "/employees/manage", element: <ManageEmployees /> },
    { path: "/payment", element: <Payment /> },
    { path: "/wallet", element: <BusinessWallet /> },
    { path: "/invoice", element: <Invoice /> },
    { path: "*", element: <BusinessHome /> },
  ]);
}