import { Col, Dropdown, Row } from "react-bootstrap";

const InvestmentInput = () => {

    return (
        <div>
            <div className="card m-2 shadow border-0">
                <div className="card-body">
                    <Row>
                        <Col>
                            <div className=""><p>Wallet or Income Stream</p> <input type="text" placeholder="Metamask" className=""/></div>
                        </Col>
                        <Col>
                            <div className=""><p className="justify-content-center align-items-center px-2">Frequency</p>
                                <Dropdown>
                                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                        Frequency
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#/weekly">Weekly</Dropdown.Item>
                                        <Dropdown.Item href="#/monthly">Monthly</Dropdown.Item>
                                        <Dropdown.Item href="#/annually">Annually</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                        <Col>
                            <div className=""><p className="px-2">Allocation</p><input type="text" placeholder="Allocation" /></div>
                        </Col>
                        <Col>
                            <div className=""><p className="px-2">Currency</p><input type="text" placeholder="Currency" /></div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default InvestmentInput;