import React from "react";
import { Col, Row } from "react-bootstrap";
// import CreateInvoice from "components/Invoice/CreateInvoice";

const InvoiceCreate = () => {
  return (
    <Row>
      <Row className="mx-auto p-3">
        <Col className="align-items-center justify-content-center mx-auto">
          <Row>
            <p className="h4">Description</p>
          </Row>
          <Row>
            <p className="h6 text-muted"> Add description and reasons </p>
          </Row>
        </Col>
      </Row>
      <Row className="mx-auto px-3">{/* <CreateInvoice /> */}</Row>
    </Row>
  );
};

export default InvoiceCreate;