import React from "react";
import { Col } from "react-bootstrap";
import DashboardChart from "../../../business/components/charts/DashboardChart";

const Graph = () => {
  return (
    <Col lg={6} md={4} sm={12} className="mb-3 mb-md-0">
      <div className="card border-0 card_shadow">
        <div className="card-body">
          <DashboardChart />
        </div>
      </div>
    </Col>
  );
};

export default Graph;