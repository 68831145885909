import { Col, Row } from "react-bootstrap";
import InvoiceTransaction from "../../../components/InvoiceTransaction";
import Metrics from "../../../components/MetricsCards";

const Invoice = () => {
  return (
    <div className="bg-primary h-100">
      <Row className="">
        <Row className="justify-content-center mx-auto my-2">
          <Col className="h-100">
            <Metrics value="3500" type="USDC" usage="Total" image="" />
          </Col>
          <Col className="h-100 ">
            <Metrics value="3000" type="USDC" usage="Scheduled" image="" />
          </Col>
          <Col className="h-100">
            <Metrics value="500" type="USDC" usage="Paid" image="" />
          </Col>
        </Row>
        <InvoiceTransaction />
      </Row>
    </div>
  );
};

export default Invoice;
