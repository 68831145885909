export const paymentData = [
  {
    id: 1,
    remark: "Prateek",
    amount: "100",
    coin: "ETH",
    date: "30th July, 2022",
  },
  {
    id: 2,
    remark: "Chilo",
    amount: "300",
    coin: "BTC",
    date: "31st July, 2022",
  },
  {
    id: 3,
    remark: "Quadri",
    amount: "150",
    coin: "SOL",
    date: "5th August, 2022",
  },
  {
    id: 4,
    remark: "Celia",
    amount: "500",
    coin: "SOL",
    date: "10th August, 2022",
  },
  {
    id: 5,
    remark: "Phidel",
    amount: "50",
    coin: "MATIC",
    date: "13th August, 2022",
  },
];


export const transaction = [
  {
    Date: "12/12/2021",
    Address: "0x1234567890",
    Token: "BTC",
    SendReceive: "Send",
    Amount: "0.0001",
    CostBasis: "0.0001",
    Labels: ["Staking", "Mining"]
},
{
    Date: "12/12/2021",
    Address: "0x1234567890",
    Token: "BTC",
    SendReceive: "Receive",
    Amount: "0.0001",
    CostBasis: "0.0001",
    Labels: ["Interest", "Staking"]
},
{
  Date: "12/12/2021",
  Address: "0x1234567890",
  Token: "BTC",
  SendReceive: "Send",
  Amount: "0.0001",
  CostBasis: "0.0001",
  Labels: ["Staking", "Mining"]
},
{
  Date: "12/12/2021",
  Address: "0x1234567890",
  Token: "BTC",
  SendReceive: "Receive",
  Amount: "0.0001",
  CostBasis: "0.0001",
  Labels: ["Interest", "Staking"]
}
];

export const tokenTransaction = [
  {
    id: 1,
    Token: "ETH",
    Type: "Credit",
    Amount: 100,
    Quantity: 3,
    Date: "2021-01-01",
    LTP: 250
},
{
    id: 2,
    Token: "BTC",
    Type: "Debit",
    Amount: 20,
    Quantity: 2,
    Date: "2021-04-01",
    LTP: 10
},
{
  id: 1,
  Token: "ETH",
  Type: "Credit",
  Amount: 100,
  Quantity: 3,
  Date: "2021-01-01",
  LTP: 250
},
{
  id: 2,
  Token: "BTC",
  Type: "Debit",
  Amount: 20,
  Quantity: 2,
  Date: "2021-04-01",
  LTP: 10
},
{
  id: 1,
  Token: "ETH",
  Type: "Credit",
  Amount: 100,
  Quantity: 3,
  Date: "2021-01-01",
  LTP: 250
},
{
  id: 2,
  Token: "BTC",
  Type: "Debit",
  Amount: 20,
  Quantity: 2,
  Date: "2021-04-01",
  LTP: 10
},
];

export const employeeData = [
  {
    userId: "rirani",
    jobTitleName: "Developer",
    firstName: "Romin",
    lastName: "Irani",
    preferredFullName: "Romin Irani",
    employeeCode: "E1",
    region: "CA",
    phoneNumber: "408-1234567",
    emailAddress: "romin.k.irani@gmail.com"
  },
  {
    userId: "nirani",
    jobTitleName: "Developer",
    firstName: "Neil",
    lastName: "Irani",
    preferredFullName: "Neil Irani",
    employeeCode: "E2",
    region: "CA",
    phoneNumber: "408-1111111",
    emailAddress: "neilrirani@gmail.com"
  },
  {
    userId: "thanks",
    jobTitleName: "Program Directory",
    firstName: "Tom",
    lastName: "Hanks",
    preferredFullName: "Tom Hanks",
    employeeCode: "E3",
    region: "CA",
    phoneNumber: "408-2222222",
    emailAddress: "tomhanks@gmail.com"
  }
];

export const BusinessInvoiceList = [
  {
    id: 1,
    clientsAddress: "0xafjkasdfhauewfawekja",
    amount: "10000",
    token: "USDC",
    due: "17/11/2022",
    status: "Paid",
  },
  {
    id: 2,
    clientsAddress: "0xakdweh890u4jnk0dfsdf",
    amount: "50000",
    token: "USDC",
    due: "1/11/2022",
    status: "pending",
  },
  {
    id: 3,
    clientsAddress: "0x8934hwe89hjdfihdfoln",
    amount: "3000",
    token: "USDC",
    due: "10/11/2022",
    status: "In Progress",
  },
  {
    id: 4,
    clientsAddress: "0xdf439857uerjhdfjfdjkd",
    amount: "8000",
    token: "USDC",
    due: "13/11/2022",
    status: "Paid",
  },
  {
    id: 5,
    clientsAddress: "0xtyskjdfnadfjnafdjasdf",
    amount: "2000",
    token: "USDC",
    due: "12/11/2022",
    status: "Paid",
  },
];