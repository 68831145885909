import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "app/store";

type PaymentType = {
  id: string;
  walletAddress: string;
  amount: string;
  startDate?: string;
  endDate?: string;
  frequency?: string;
  acknowledge?: boolean;
  reason?: string;
  paymentType: string;
};

interface TransactionState {
  amount: string;
  fixedPayments: Array<PaymentType>;
  periodicPayments: Array<PaymentType>;
  streamPayments: Array<PaymentType>;
}

const initialState: TransactionState = {
  amount: "",
  fixedPayments: [],
  periodicPayments: [],
  streamPayments: [],
};

export const transactionSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    setAmount: (state: TransactionState, action) => {
      state.amount = action.payload;
    },
    setFixed: (state: TransactionState, action) => {
      state.fixedPayments.push(action.payload);
    },
    setPeriodic: (state: TransactionState, action) => {
      state.periodicPayments.push(action.payload);
    },
    setStream: (state: TransactionState, action) => {
      state.streamPayments.push(action.payload);
    },
  },
});

export const { setAmount, setFixed, setStream, setPeriodic } = transactionSlice.actions;

export const selectWallet = (state: RootState) => state.wallet;

export default transactionSlice.reducer;
