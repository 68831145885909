import { useAppDispatch } from "app/hooks";
import { useConnectUserWalletMutation, WalletInterface } from "app/services/authService";
import { useCallback, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { setAuthenticatedUserType, setLoggedIn } from "reducers/auth/authSlice";
import { setFullPageLoader } from "reducers/layout/layoutSlice";
import { setWalletAddress } from "reducers/wallet/walletSlice";
import { PATHS, USERS } from "utils/constants";
import { useAccount, useConnect } from "wagmi";

type Props = {
  handleClose: () => void;
  show: boolean;
  isBusinessMode: boolean;
};

export default function ConnectWalletModal({ isBusinessMode, show, handleClose }: Props) {
  const { isConnected, address } = useAccount();
  const { connect, connectors, isLoading, pendingConnector, error } = useConnect();
  const [connectClicked, setConnectClicked] = useState(false);
  const [connectUserWallet] = useConnectUserWalletMutation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const authenticateWallet = useCallback(
    async (account: string | undefined) => {
      if (!account) {
        toast.error("Address not found");
        return;
      }
      dispatch(setFullPageLoader(true));
      const { data: res } = (await connectUserWallet(account)) as unknown as WalletInterface;
      if (res) {
        dispatch(setAuthenticatedUserType(isBusinessMode ? USERS.BUSINESS : USERS.PERSONAL));
        dispatch(setWalletAddress(account));
        setTimeout(() => {
          dispatch(setFullPageLoader(false));
          if (res.data.numberOfVisits === 0 || !res.data.walletId) {
            dispatch(setLoggedIn(false));
            navigate(isBusinessMode ? PATHS.BUSINESS_REGISTRATION : PATHS.PERSONAL_REGISTRATION);
            return;
          }
          dispatch(setLoggedIn(true));
          navigate(PATHS.DASHBOARD);
        }, 1000);
      }
    },
    [connectUserWallet, dispatch, isBusinessMode, navigate],
  );

  useEffect(() => {
    if (isConnected && connectClicked) {
      authenticateWallet(address);
      return;
    }
    if (error && connectClicked) {
      toast.error(error.message);
      if (error.name === "ConnectorAlreadyConnectedError") {
        toast.error("This wallet is already connected");
      }
    }
  }, [address, authenticateWallet, connectClicked, isConnected, error]);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title> Connect to your wallet </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column justify-content-center my-3">
          {connectors.map((connector) => (
            <Button
              disabled={!connector.ready}
              key={connector.id}
              onClick={() => {
                setConnectClicked(true);
                connect({ connector });
              }}
            >
              {connector.name}
              {isLoading && pendingConnector?.id === connector.id && " (connecting)"}
            </Button>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
