import React from "react";
import ReactApexChart from "react-apexcharts";

class AreaChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [
        {
          name: "Expenditure",
          data: [31, 40, 28, 51, 42, 109, 100],
        },
        {
          name: "Projected Expenditure",
          data: [11, 32, 45, 32, 34, 52, 41],
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "area",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "date",
          categories: ["Jan", "Feb", "March", "April", "May", "June", "July"],
        },
        tooltip: {
          x: {
            format: "dd/MM/yy",
          },
        },
      },
    };
  }

  render() {
    return (
      <div id="chart" className="">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="area"
          height={250}
          width="100%"
        />
      </div>
    );
  }
}

export default AreaChart;
